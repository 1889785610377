import React, { useEffect, useState } from "react";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";
import Header from "../layout/Header";

type Props = {
    // subject: string,
    // subjectSub: string,
    // retry?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    // supportText?: string,
    // showSupportText?: Boolean,
    // body?: React.ReactNode
    // mode?: 'full' | 'default'
}

const Timer: React.FC<Props> = ({
    // subject,
    // subjectSub,
    //retry = true,
    onClick,
    // showSupportText = true,
    // supportText = "For more information and support, please contact ",
    // body,
    // mode = 'default'
}) => {
    const [duration, setDuration] = useState(10);
    const [paused, setPaused] = useState(false);

    useEffect(() => {
        let timerId: any;
        console.log(duration, paused, 'timer_____');
        if (!paused) {
            timerId = setInterval(() => {
                setDuration((prev) => prev - 1);
            }, 1000);
            console.log(timerId);
        }

        if(duration === 0){
            setDuration(0)
            clearInterval(timerId);
        }

        return function cleanup() {
            console.log(`Clearing ${timerId}`);
            clearInterval(timerId);
        };
    }, [paused, duration]);

    const handleClick = (e: any) => {
        !paused ? setPaused(true) : setPaused(false);
    };

    return (
        <>
            {duration >= 0 ? <>Resend OTP in {duration} sec</> :
                <>
                    {onClick && (<span onClick={onClick}>Resend OTP</span>)}
                </>}
            {/* <button paused={paused} onClick={handleClick} /> */}
        </>
    );

}

export default Timer;
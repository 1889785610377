import * as React from 'react';
import axios from 'axios';
import { BASE_API_URL, CONNECTION_TIMEOUT, USER_TOKEN } from '../config';

axios.defaults.baseURL = BASE_API_URL;
axios.defaults.timeout = CONNECTION_TIMEOUT;
//axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
//axios.defaults.headers.common.accept = 'application/json';

axios.interceptors.request.use(
  async (config) => {
    const token: string | null = localStorage.getItem(USER_TOKEN) || null;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export async function user(policy: string | null) {
  const { data } = await axios.get(`user?current_policy=${policy}`);
  return data;
}

export async function auth_check_phone(value: Object) {
  const { data } = await axios.post(`user/check-phone`, value);
  return data;
}

export async function auth_request_otp(value: Object) {
  const { data } = await axios.post(`user/request-otp`, value);
  return data;
}

export async function auth_validate_otp(value: Object) {
  const { data } = await axios.post(`user/validate-phone`, value);
  return data;
}

export async function get_policy(policy_number: string) {
  const { data } = await axios.get(`policy/policynos/${policy_number}`);
  return data;
}

export async function claims_get_types() {
  const { data } = await axios.get(`claim-status/types`);
  return data;
}

export async function claims_get_banks() {
  const { data } = await axios.get(`banks`);
  return data;
}

export async function claims_get_all(policy_number: string) {
  const { data } = await axios.get(`claim-status/list/${policy_number}`);
  return data;
}

export const claims_bootstaps = async (policy_number: string) => {
  let [types, claims, banks] = await axios.all([claims_get_types(), claims_get_all(policy_number), claims_get_banks()]);
  return { types: types?.data, claims: claims?.data, banks: banks?.data };
}

export async function dowload_statement(policy_number: string) {
  const { data } = await axios.get(`download/statement/` + policy_number);
  return data;
}

export async function payment_init(value: any) {
  const { data } = await axios.post(`payment/initialize`, value);
  return data;
}

export async function payment_verify(reference: any) {
  const { data } = await axios.post(`payment/verify`, { reference });
  return data;
}

export async function upload_file(values: any) {
  const { data } = await axios.post(`upload`, values);
  return data;
}

export async function make_claim(values: any) {
  const { data } = await axios.post(`claims/make-claim`, values);
  return data;
}

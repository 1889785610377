import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../../components/DigitFormat';
import { Btn, Input, Select } from '../../../components/Forms';
import SideSummary from '../../../components/SideSummary';
import AppContext from '../../../context/AppContext';
import LayoutContext from '../../../context/LayoutContext';
import useRouter from '../../../hooks/useRouter';
import { make_claim, upload_file } from '../../../services';
import ClaimDetails from './claim-details';
import ClaimSummary from './claim-summary';
import ClaimError from './claim-error';
import ClaimDone from './claim-done';
import LoaderClaim from '../../../components/loader/LoaderClaim';
import NotifyMode from '../../../components/NotifyMode';

type Props = {
    [x: string]: any;
};

export const MakeClaimContext = React.createContext<{ [x: string]: any; }>({});

const MakeClaim: React.FC = () => {
    const { claimsError, claimsIsLoading, setIsSubmitting, claimsRefetch, activePolicy } = React.useContext(AppContext);
    const { } = React.useContext(LayoutContext);

    const [documents, setDocuments] = React.useState<any[]>([]);
    const [currentIndex, setCurrentIndex] = React.useState<number>(1);
    const [values, setValues] = React.useState<Props>({});
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [ticket, setTicket] = React.useState({});

    const onClaimSubmit = React.useCallback((data: any) => {
        setIsSubmitting(true);

        const postData = {
            ...data,
            pol_num: activePolicy.policy_number,
            claimant_name: activePolicy.full_name,
            claimant_email: activePolicy.email_address,
            claimant_phone: activePolicy.phone_number,
            doc_name: documents[0].file_name,
            doc_url: documents[0].url,
        }

        make_claim(postData).then(({ ticket }) => {
            setTicket(ticket);
            setCurrentIndex(0);
            setDocuments([]);
            setValues({});
        }).catch(error => {
            setErrorMessage(error?.response?.data?.message || "There seems to be an error, please try again");
            setCurrentIndex(-1);
        }).then(() => {
            setIsSubmitting(false);
        });
    }, [values]);

    React.useEffect(() => {
        return () => {
            setCurrentIndex(1);
        }
    }, []);


    React.useEffect(() => {
        console.log(values, 'values_____');
    }, [values]);

    return (<MakeClaimContext.Provider value={{
        currentIndex,
        setCurrentIndex,
        values,
        setValues,
        documents,
        setDocuments,
        onClaimSubmit,
        errorMessage,
        ticket
    }}>
        <div className="heading-container">
            <h1>Make A Claim</h1>
            <p>Make claims on all your policies.</p>
        </div>

        {claimsIsLoading ? <LoaderClaim /> : claimsError ? 
        
        <NotifyMode
                    subject='Seems there is a problem!'
                    subjectSub="Can not initiate claims process at the moment, we will keep trying."
                    supportText='If issue persist, please contact'
                    onClick={claimsRefetch}
                />
        : <>
            {currentIndex < 0 ?
                <ClaimError /> : currentIndex === 0 ? <ClaimDone /> :
                    <div className="row">
                        <div className="col-md-5 mb-4 d-md-block d-none">
                            <SideSummary />
                        </div>
                        <div className="col-md-7">
                            <ClaimDetails />
                            <ClaimSummary />
                        </div>
                    </div>}
        </>}




    </MakeClaimContext.Provider>)

}

export default MakeClaim;
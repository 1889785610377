import React from "react";

const LoaderCard: React.FC = () => {

    return (<div className="summary-card pb-3 app__loading">
        <h3 className="heading app__skeleton"></h3>
        <div className="sc-content">
            <label className="app__skeleton w-50 skl__stick_v_10 mb-1" />
            <div className="app__skeleton w-75 skl__stick_v_12" />
        </div>

        <div className="sc-content">
            <label className="app__skeleton w-50 skl__stick_v_10 mb-1" />
            <div className="app__skeleton w-80 skl__stick_v_12" />
        </div>

        <div className="sc-content">
            <label className="app__skeleton w-50 skl__stick_v_10 mb-1" />
            <div className="app__skeleton w-100 skl__stick_v_12" />
        </div>
    </div>);
}

export default LoaderCard;
import * as React from 'react';
import commonActions from './CommonActions';

const dataActions = {
    checkNil: (str: string): string | null => {
        const check: any[] = [null, "nil", "NULL", "null", "", " "];
        if (!check.includes(str)) {
            return str;
        }

        return null;
    },
    frequency: (abbr: string) => {
        let freq = 'M';
        switch (abbr) {
            case 'M':
                freq = 'Monthly';
                break;
            case 'Q':
                freq = 'Quarterly';
                break;
            case 'A':
                freq = 'Annual';
                break;
            case 'S':
                freq = 'Semi-Annual';
                break;
            default:
                freq = 'Monthly';
        }
        return freq;
    },
    badge(value: string) { //SURRENDERED
        let res;
        switch (value) {
            case 'ACTIVE':
            case 'MATURED':
                res = 'success';
                break;
            case 'LAPSED':
            case 'SURRENDERED':
                res = 'danger';
                break;
            case 'FINAL LAPSED':
                res = 'warning';
                break;
            case 'PAID UP':
                res = 'secondary';
                break;
            default:
                res = 'warning';

        }

        return <span className={`badge bg-${res}`}>{value}</span>;
    },
    extractPolicyData: (data: any) => {
        let policy_number;

        if (dataActions.checkNil(data?.POLICYNO) && commonActions.isAlpha(data?.POLICYNO)) {
            policy_number = data?.POLICYNO;
        } else if (dataActions.checkNil(data?.POL_REF_NO) && commonActions.isAlpha(data?.POL_REF_NO)) {
            policy_number = data?.POL_REF_NO;
        } else {
            policy_number = data?.PRODCODE;
        }

        return {
            policy_number,
            description: commonActions.capitalize(data?.PROD_DESC),
            full_name: commonActions.capitalize(data?.FULLNAME),
            email_address: dataActions.checkNil(data?.EMAILADDR) ? (data?.EMAILADDR).toLowerCase() : null,
            phone_number: data?.MOBILENO,
        }
    },
}

export default dataActions;
import { Link } from 'react-router-dom';

const Nav = () => {

    return (<nav>
        <div className="ltn__main-menu">
            <ul>
                <li className="menu-icon">
                    <span><Link to="/">Policy</Link></span>
                </li>
                <li className="menu-icon">
                    <span>Payment</span>
                    <ul>
                        <li><Link to="/payment-history">Payment History</Link></li>
                        <li><Link to="/payment">Make Payment</Link></li>
                    </ul>
                </li>
                <li className="menu-icon">
                    <span><Link to="/claims">Claims</Link></span>
                </li>

                <li className="special-link">
                    <Link to="/make-a-claim">Make Claim</Link>
                </li>
            </ul>
        </div>
    </nav>)

}

export default Nav;
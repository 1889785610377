import * as React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../components/DigitFormat';
import AppContext from '../../context/AppContext';
import LayoutContext from '../../context/LayoutContext';

const Home = () => {
    const { dispatch, errorResponse, userCurrentPolicy, checkArrayData, frequency, userPolicies, userCurrentPolicyData, logoutUser, userData, checkArray, activePolicy, badge, capitalize, formatDateInput } = React.useContext(AppContext);

    const { polStatusDesc, totalPremium, policyFreq, premium, lifeAssured, polEffectiveDate, polMaturityDate, policyDurations, beneficiaries, dependants, prpTel, prpEmail, prpPhysicalAddr, totalPremiumPaid, totalPremiumDue, agentDetails } = React.useContext(LayoutContext);

    return (<div className='fade-in'>
        <div className="row mb-2 dash-v-block">
            <div className="col-md-6">
                <div className="card-style-1">
                    <div className="card-container">
                        <h3 className="title">Policy Summary</h3>
                        <div className="text-lg">{activePolicy?.policy_number}</div>
                        <div>{activePolicy?.description}</div>
                        {/* <span className="badge bg-success">Active</span> */}
                        {polStatusDesc && badge(polStatusDesc)}
                    </div>
                </div>
            </div>

            <div className="col-md-6 d-md-block d-none">
                <div className="card-style-1">
                    <div className="card-container">
                        <h3 className="title">Premium Total</h3>
                        <div className="text-lg"><Currency value={totalPremium} /></div>
                        <div>Total Premium Payable</div>

                        {policyFreq && (<div className="font-size-15">Your premium is <strong><Currency value={premium} /></strong> {(policyFreq).toLowerCase()}
                        </div>)}

                    </div>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
                {/* <div className="summary-card">
                    <div className='bg-light'>sdfsd dsfsdf</div>

                </div> */}

                <div className="summary-card">
                    <h3 className="heading">Policy Details</h3>
                    <div className="sc-content">
                        <label>Assured Name</label>
                        <div>{lifeAssured}</div>
                    </div>
                    <div className="sc-content">
                        <label>Policy Name</label>
                        <div>{activePolicy?.description}</div>
                    </div>
                    <div className="sc-content">
                        <label>Policy Number</label>
                        <div>{activePolicy?.policy_number}</div>
                    </div>
                    <div className="sc-content">
                        <label>Effective Date</label>
                        <div>{formatDateInput(polEffectiveDate)}</div>
                    </div>
                    <div className="sc-content">
                        <label>Maturity Date</label>
                        <div>{formatDateInput(polMaturityDate)}</div>
                    </div>
                    <div className="sc-content">
                        <label>Policy Duration</label>
                        <div>{policyDurations}</div>
                    </div>
                    <div className="sc-content">
                        <label>Policy Status</label>
                        <div>{capitalize(polStatusDesc)}</div>
                    </div>
                </div>

                {checkArrayData(beneficiaries) && (<div className="summary-card">
                    <h3 className="heading">
                        <span>Beneficiaries</span>
                        {beneficiaries.length > 1 && (<a href="#">View all beneficiaries</a>)}
                    </h3>
                    <div className="sc-content">
                        <label>Beneficiary Name</label>
                        <div>{beneficiaries[0]?.benFirstName} {beneficiaries[0]?.benOtherNames}</div>
                    </div>
                    <div className="sc-content">
                        <label>Birth Date</label>
                        <div>{formatDateInput(beneficiaries[0]?.benDob)}</div>
                    </div>
                </div>)}

                {checkArrayData(dependants) && (<div className="summary-card">
                    <h3 className="heading">
                        <span>Dependants</span>
                        {dependants.length > 1 && (<a href="#">View all dependants</a>)}
                    </h3>
                    <div className="sc-content">
                        <label>Dependant Name</label>
                        <div>{dependants[0]?.podSurname} {dependants[0]?.podOtherNames}</div>
                    </div>
                    <div className="sc-content">
                        <label>Birth Date</label>
                        <div>{formatDateInput(dependants[0]?.podDob)}</div>
                    </div>
                </div>)}

            </div>
            <div className="col-md-6">
                <div className="summary-card">
                    <h3 className="heading">Contact Details</h3>
                    <div className="sc-content">
                        <label>Phone Number</label>
                        <div>{prpTel}</div>
                    </div>
                    {prpEmail && (<div className="sc-content">
                        <label>Email Address</label>
                        <div>{(prpEmail).toLowerCase()}</div>
                    </div>)}
                    <div className="sc-content">
                        <label>Contact Address</label>
                        <div>{capitalize(prpPhysicalAddr)}</div>
                    </div>
                </div>


                <div className="summary-card">
                    <h3 className="heading">
                        <span>Payment Details</span>
                        <Link to="/payment">Make Payment</Link>
                    </h3>
                    <div className="sc-content">
                        <label>Premium Payable Total</label>
                        <div><Currency value={totalPremium} /></div>
                    </div>
                    <div className="sc-content">
                        <label>Premium</label>
                        <div><Currency value={premium} /></div>
                    </div>
                    <div className="sc-content">
                        <label>Payment Frequency</label>
                        <div>{policyFreq}</div>
                    </div>
                    <div className="sc-content">
                        <label>Total Premium Paid</label>
                        <div><Currency value={totalPremiumPaid} /></div>
                    </div>
                    <div className="sc-content">
                        <label>Total Premium Pending</label>
                        <div><Currency value={totalPremiumDue} /></div>
                    </div>
                </div>

                {agentDetails && (<div className="summary-card">
                    <h3 className="heading">
                        <span>Financial Advisor Details</span>
                        <Link to={`tel:${agentDetails?.agent_phone}`}>Call Financial Advisor</Link>
                    </h3>
                    <div className="sc-content">
                        <label>Financial Advisor Name</label>
                        <div>{agentDetails?.agent_name}</div>
                    </div>
                    <div className="sc-content">
                        <label>Phone Number</label>
                        <div>{agentDetails?.agent_phone}</div>
                    </div>
                    <div className="sc-content">
                        <label>Email Address</label>
                        <div>{agentDetails?.agent_email}</div>
                    </div>
                </div>)}



            </div>
        </div>
    </div>)

}

export default Home;
import * as React from 'react';
import { CURRENT_POLICY, USER_TOKEN } from '../../config';

export default {
    setUserData: async (dispatch: any, userToken: string, userData: Object, userPolicies: any[], userCurrentPolicy: string, userCurrentPolicyData: string, activePolicy: string) => {
        localStorage.setItem(USER_TOKEN, userToken);
        localStorage.setItem(CURRENT_POLICY, userCurrentPolicy);
        dispatch({
            type: 'LOGIN',
            userToken,
            userData,
            userPolicies,
            userCurrentPolicy,
            userCurrentPolicyData,
            activePolicy
        });
    },
    logoutUser: (dispatch: any) => {
        localStorage.removeItem(USER_TOKEN);
        localStorage.removeItem(CURRENT_POLICY);
        dispatch({ type: 'LOGOUT' });
        //navigate('/get-started');
    },
    updateCurrentPolicy: (dispatch: any, activePolicy: any) => {
        localStorage.setItem(CURRENT_POLICY, activePolicy?.policy_number);
        dispatch({
            type: 'UPDATE_CURRENT_POLICY',
            userCurrentPolicy: activePolicy?.policy_number,
            activePolicy
        });
    },
    validateEmail: (value: string, notRequired: Boolean = false) => {
        //const value = getValues(name);
        const exp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/

        if (!notRequired && !value) {
            return 'Email address is required!'
        }

        if (!notRequired && value && !exp.test(value)) {
            return 'Email address is not valid!'
        }

        return true;
    },

}
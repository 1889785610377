import React from "react";
import { IMAGE_URL } from "../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LayoutContext from "../context/LayoutContext";
import { LinkDetails } from "./Links";
import AppContext from "../context/AppContext";
import { Currency } from "./DigitFormat";


type Props = {
    //[x: string]: any;
}

const SideSummary: React.FC = () => {
    const { badge } = React.useContext(AppContext);
    const { activePolicy, polStatusDesc, totalPremium, totalPremiumPaid, totalPremiumDue } = React.useContext(LayoutContext)

    return (<div className="card-style-1 mh-auto">
        <div className="card-container">
            <div className="">
                <div className="text-lg font-size-24">{activePolicy?.policy_number}</div>
                <div>{activePolicy?.description}</div>
                {polStatusDesc && badge(polStatusDesc)}
            </div>
            <hr className="inverse" />
            <div>
                <div className="smy-block smy-block-inverse mb-2 left">
                    <div className="smy-block">
                        <div className="smy-label">Total Premium Payable</div>
                        <div className="smy-text"><Currency value={totalPremium} /></div>
                    </div>
                </div>

                <div className="smy-block smy-block-inverse  mb-2">
                    <div className="smy-label">Total Premium Paid</div>
                    <div className="smy-text"><Currency value={totalPremiumPaid} /></div>
                </div>

                <div className="smy-block smy-block-inverse">
                    <div className="smy-label">Premium Amount Due</div>
                    <div className="smy-text font-size-24"><Currency value={totalPremiumDue} /></div>
                </div>
            </div>
        </div>
    </div>);
}

export default SideSummary;
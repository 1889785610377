
import { NumericFormat } from 'react-number-format';

type Props = {
    value: number | string | undefined,
}

export const Currency: React.FC<Props> = ({ value }) => {
    return <NumericFormat
        value={value}
        displayType={'text'} thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        renderText={value => <>&#8358;{value}</>} />;
}

export const NumberFormat: React.FC<Props> = ({ value }) => {
    return <NumericFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
    />;
}
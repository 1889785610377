import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../../components/DigitFormat';
import { Btn, Input, Select } from '../../../components/Forms';
import SideSummary from '../../../components/SideSummary';
import AppContext from '../../../context/AppContext';
import ClaimsContext from '../../../context/ClaimsContext';
import LayoutContext from '../../../context/LayoutContext';
import useRouter from '../../../hooks/useRouter';
import { upload_file } from '../../../services';
import { MakeClaimContext } from '.';

const ClaimDetails: React.FC = () => {
    const { claimTypes, isSubmitting, sizeInMB, checkArrayData, banks } = React.useContext(AppContext);
    const { } = React.useContext(LayoutContext);
    const { currentIndex, setCurrentIndex, documents, setDocuments, setValues, values } = React.useContext(MakeClaimContext);

    const [formID] = React.useState('form-claims');
    const [uploadFileError, setUploadFileError] = React.useState<any>(null);
    const inputRef = React.useRef<HTMLInputElement | any>(null);

    const { handleSubmit, formState: { errors }, register } = useForm<IFormInputs>({
        defaultValues: { ...values }
    });

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        setValues(data);
        console.log(data, 'SubmitHandler_____')
        setCurrentIndex(2);
    }

    const uploadError =
        React.useCallback((id: string, file_name: string, error = 'An error occurred, please try again!') => {
            setDocuments(documents.filter((dt: any) => dt.id !== id));
            setUploadFileError([file_name, error]);
        }, [documents]);

    const removeFile = React.useCallback((id: string) => {
        const conf = window.confirm("This file will be deleted, Click OK to continue!");
        if (!conf) {
            return;
        }
        setDocuments(documents.filter((dt: any) => dt.id !== id));
    }, [documents]);

    const initFile =
        React.useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {

            if (document.querySelectorAll("#upload-file-list-box .upload-file-list-error").length > 0) {
                const ltn__errors = [...Array.from(document.querySelectorAll('.upload-file-list-error'))];
                ltn__errors.forEach((element) => {
                    element.remove();
                });
            }

            if (event.target.files && event.target.files.length) {
                const [file] = event.target.files;
                const id = (Math.random().toString().substring(2));
                let updatedDocs = [/* ...documents,  */{
                    id,
                    status: 2,
                    file_name: file.name,
                }];

                setDocuments(updatedDocs);
                processFile(id, file, updatedDocs);
            }
        }, [documents]);

    const processFile = React.useCallback(async (id: string, file: any, documents: any[]) => {
        let formData = new FormData();
        let uploadeResult: any;
        //let btnNext = document.getElementById('btn-next');
        //btnNext.disabled = true;
        if (sizeInMB(file.size) > 2.1) {
            uploadError(id, file.name, 'File size can not be more than 2mb!');
            return;
        }

        try {
            formData.append('file', file);
            const { data } = await upload_file(formData);
            const updatedDocs = documents.map((doc: any) => {
                if (doc.id === id) {
                    return {
                        ...doc,
                        status: 1,
                        url: data.file_url
                    };
                } else {
                    return doc;
                }
            });

            setDocuments(updatedDocs);
            inputRef.current.value = "";

        } catch (error: any) {
            if (error?.response?.data?.error) {
                uploadError(id, file.name, error?.response?.data?.error);
            } else {
                uploadError(id, file.name);
            }
            return;
        } finally {
            //btnNext.disabled = false;
            //btnPrev.disabled = false;
        }

    }, [documents]);

    React.useEffect(() => {
        console.log(documents, 'updatedDocs____')

        if (uploadFileError) {
            const container = document.getElementById('upload-file-list-box');
            const childEl = document.createElement('div');
            childEl.classList.add("upload-file-list");
            childEl.classList.add("upload-file-list-error");
            childEl.innerHTML =
                `<div class="smy-ssb">
                    <div class="lh-1-3">
                        <div class="upload-file-item">
                            <img src="/assets/images/error.svg" />
                            <span>${uploadFileError[0]}</span>
                        </div>
                        <span class="text-error upload-file-text">${uploadFileError[1]}</span>
                    </div>
                    <span></span>
                </div>`;

            container?.appendChild(childEl);
            setUploadFileError(null);
            inputRef.current.value = "";
        }
    }, [documents, uploadFileError]);

    return (<>{currentIndex === 1 && (<form id={formID} onSubmit={handleSubmit(onSubmit)} className="fade-in">
        <div className="form-group-block pt-0 pb-4">
            <div className="form-group mb-0">
                <div className="title-v-container">
                    <h4>Claim Type</h4>
                    <p className='pb-2'>Select the type of claim you want to make</p>
                </div>

                <div>
                    <div className="btn-group btn-group-toggle bgt-vertical"
                        data-toggle="buttons">

                        {checkArrayData(claimTypes) && (claimTypes)
                            .map((row: any) => {
                                return (<>
                                    <input type="radio" value={row.key} {...register('claim_type', {
                                        required: "Claim type is required!"
                                    })} id={row.id} autoComplete="off"
                                    />
                                    <label className="btn" key={row.id} htmlFor={row.id} >

                                        <div className="bgt-container">
                                            <div>
                                                <h5>{row.name}</h5>
                                                <p>Claim type dedscriptions</p>
                                                {/* <p>{row?.description}</p> */}
                                            </div>
                                        </div>
                                    </label>
                                </>)
                            })}
                    </div>

                    {errors && <ErrorMessage
                        errors={errors}
                        name="claim_type"
                        render={({ message }) => <span className="text-error">{message}</span>}
                    />}

                </div>
            </div>
        </div>

        <div className="form-group-block pt-4 pb-3">
            <div className="title-v-container mb-4">
                <h4>Payment Information</h4>
                <p>Please fill in accurate bank information</p>
            </div>

            <div className='row'>
                <div className='col-sm-6'>
                    <div className="form-group">
                        <Select label='Account Bank' name="bank_name" placeholder="- Select Bank -" register={register}
                            errors={errors}
                            validate={{
                                required: "Please select bank!"
                            }}
                        >
                            {checkArrayData(banks) && banks.map((dt: any) => <option key={dt.slug} value={dt.slug}>{dt.name}</option>)}
                        </Select>
                    </div>
                </div>

                <div className='col-sm-6'>
                    <div className="form-group">
                        <Select label='Account Type' name="acc_type" placeholder="- Select Account Type -" register={register}
                            errors={errors}
                            validate={{
                                required: "Please select account type!"
                            }}
                        >
                            <option value="savings">Savings</option>
                            <option value="current">Current</option>
                        </Select>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className="form-group">
                        <Input label='Account Name' name="acc_name" placeholder="Enter account name" register={register}
                            errors={errors}
                            validate={{
                                required: "Account name is required!"
                            }}
                        />
                    </div>
                </div>

                <div className='col-sm-6'>
                    <div className="form-group">
                        <Input label='Account Number' type='number' name="acc_num" placeholder="Enter account number" register={register}
                            errors={errors}
                            maxLength={10}
                            validate={{
                                required: "Account number is required!"
                            }}
                        />
                    </div>
                </div>
            </div>

        </div>

        <div className="form-group-block pt-4 pb-3">
            <div className="title-v-container mb-3">
                <h4>Upload Related Documents</h4>
                <p>Please provide any supporting document availale. E.g: Government ID card, Police report</p>
                <p className='text-secondary'><strong>Only images and pdf documents are allowed.</strong></p>
            </div>

            <div>
                <button type="button" className="btn-upload" role="button">
                    + Upload file
                    <input
                        onChange={initFile}
                        accept="image/jpeg, image/png, application/pdf"
                        type="file"
                        id="customFile"
                        tabIndex={-1}
                        ref={inputRef}
                        disabled={checkArrayData(documents) && documents[0].status === 2}
                    />
                </button>

                <div id='upload-file-list-box' className='upload-file-list-box'>
                    {checkArrayData(documents) && documents.map((file: any) => {
                        return (<div className='upload-file-list' key={file.id}>
                            <div className="smy-ssb">
                                <div className='lh-1-3'>
                                    <div className='upload-file-item'>
                                        {file.status === 1 && <img src='/assets/images/checked.svg' />}
                                        {file.status === 2 && <img src='/assets/images/loader.svg' />}
                                        {file.error && <img src='/assets/images/error.svg' />}
                                        <span>{file.file_name}</span>
                                    </div>
                                    {file.error && <span className="text-error upload-file-text">{file.error}</span>}
                                </div>

                                {file.status === 1 ? <span onClick={() => removeFile(file.id)} className="font-size-13 upload-file-trash">
                                    <i className="fas fa-trash-alt text-error"></i>
                                </span> : <span></span>}
                            </div>
                        </div>)
                    })}
                </div>

            </div>

        </div>

        <div className='pt-3 text-end'>
            <button disabled={(isSubmitting || (documents).length === 0)} type='submit' className="btn btn-round theme-btn-1 btn-effect-2">Continue</button>

        </div>
    </form>)}</>)

}

export default ClaimDetails;
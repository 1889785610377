import { Path, UseFormRegister } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"

interface IFormValues {
    [x: string]: any
}

interface InputProps {
    label?: string | React.ReactNode | any
    name: string
    type?: string
    placeholder?: string
    register: UseFormRegister<IFormValues>
    validate?: Object,
    errors?: any,
    [x: string]: any
}

interface SelectProps extends InputProps {
    children: React.ReactNode
}

interface TextareaProps extends InputProps {
    [x: string]: any
}

interface ButtonProps {
    label?: string | React.ReactNode | any
    type?: any
    className?: string,
    submitting?: boolean,
    submittingText?: string,
    submittingSize?: number,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

export const Input: React.FC<InputProps> = ({
    label,
    name,
    type = "text",
    placeholder,
    register,
    validate = {},
    errors,
    ...props
}) => (
    <>
        {label && <label>{label}</label>}
        <input {...props} type={type} placeholder={placeholder}
            {...register(name, validate)}
            className={errors && errors[name] ? "error" : ""}
            aria-invalid={errors && errors[name] ? "true" : "false"} />
        {errors && <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <span className="text-error">{message}</span>}
        />}
    </>
)

export const Textarea: React.FC<TextareaProps> = ({
    label,
    name,
    type = "text",
    placeholder,
    register,
    validate = {},
    errors,
    ...props
}) => (
    <>
        {label && <label>{label}</label>}
        <textarea
            {...props}
            placeholder={placeholder}
            {...register(name, validate)}
            className={errors && errors[name] ? "error" : ""}
            aria-invalid={errors && errors[name] ? "true" : "false"}
        ></textarea>
        {errors && <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <span className="text-error">{message}</span>}
        />}
    </>
)

export const Select: React.FC<SelectProps> = ({
    children,
    label,
    name,
    placeholder,
    register,
    validate = {},
    errors
}) => (
    <>
        {label && <label>{label}</label>}
        <select
            {...register(name, validate)}
            className={errors[name] ? "error" : ""}
        >
            {placeholder && <option value="">{placeholder}</option>}
            {children}
        </select>
        {errors && <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => <span className="text-error">{message}</span>}
        />}
    </>
)

export const Btn: React.FC<ButtonProps> = ({
    label = <>SUBMIT</>,
    type = "submit",
    className = "btn theme-btn-1",
    submitting = false,
    onClick,
    submittingSize = 25,
    submittingText,
    ...props
}) => (
    <>
        <button {...props} onClick={onClick} disabled={submitting}  className={className} type={type}>
            {submitting ?
                <>
                    <img style={{ width: submittingSize + 'px' }} src="/assets/images/loader-light.svg" />
                    {submittingText && <span style={{ display: 'inline-block', marginLeft: '5px' }}>{submittingText}</span>}
                </>
                : label}
        </button>
    </>
)


import React, { lazy, Suspense, useCallback } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Routes, Route, Link, createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import ErrorPage from "./Error.Page";
import Layout from "./layout";
import { useQuery } from 'react-query';
import AppContext from "./context/AppContext";
import { authActions, commonActions, errorResponseActions, dataActions } from "./context/actions";
import { CURRENT_POLICY, USER_TOKEN } from "./config";
import { claims_bootstaps, claims_get_all, user } from "./services";
import NotFound from "./not-found/NotFound";
import { AppReducer, initialState } from "./context/reducer";
import Home from "./pages/home";
import Onboard from "./pages/onboard";
import PaymentHistory from "./pages/payment/payment-history";
import MakePayment from "./pages/payment/make-payment";
import Claims from "./pages/claims";
import ClaimDetails from "./pages/claims/details";
import MakeClaim from "./pages/claims/make-claim";
import Loader from "./components/loader/Loader";
import NotifyMode from "./components/NotifyMode";
import LoaderHeader from "./components/loader/LoaderHeader";

const App: React.FC = () => {
  const [state, dispatch] = React.useReducer(AppReducer, initialState);
  let userToken = localStorage.getItem(USER_TOKEN) || null;
  let userCurrentPolicy = localStorage.getItem(CURRENT_POLICY) || null;
  const [errorStatus, setErrorStatus] = React.useState<any>(null);
  const [firstname, setFirstname] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [cd_error, set_cd_error] = React.useState<boolean>(false);

  const {
    isLoading: userDataLoading,
    error: userDataError,
    isFetching: userDataFetching,
    refetch: userDataRetry,
  } =
    useQuery(['customer-data', state.userCurrentPolicy], () => user(state.userCurrentPolicy), {
      retry: 0,
      enabled: Boolean(state.userToken),
      //refetchInterval: 50000,
      //keepPreviousData: true,
      onSuccess: async ({ data: { userData, policyData, currentPolicyData, activePolicy } }) => {
        //console.log(currentPolicyData, 'SET_APP_DATA______')

        //if current policy number is not set, logout user
        if (!state.userCurrentPolicy || !activePolicy) {
          authActions.logoutUser(dispatch);
          return;
        }

        console.log(activePolicy, 'activePolicy_____')

        //if activePolicy null, maintain old active policy
        const ap = (!activePolicy) ? state.activePolicy : activePolicy;

        dispatch({
          type: 'SET_APP_DATA',
          userData,
          userPolicies: policyData,
          userCurrentPolicyData: currentPolicyData,
          activePolicy: ap
        });
      },
      onError: (error: any) => {
        const ErrorStatus = error?.response?.status;
        console.log(ErrorStatus, 'ErrorStatus_____');
        if (ErrorStatus === 401 || ErrorStatus === 403) {
          authActions.logoutUser(dispatch);
          return;
        } else {
          set_cd_error(true);
        }
      }
    });

  /* Get claims data */
  const {
    isLoading: claimsIsLoading,
    error: claimsError,
    isFetching: claimsIsFetching,
    refetch: claimsRefetch
  } =
    useQuery(['claims-data', state.userCurrentPolicy], () => claims_bootstaps(state.userCurrentPolicy), {
      retry: 0,
      enabled: Boolean(state.userCurrentPolicyData),
      //refetchInterval: 50000,
      //keepPreviousData: true,
      onSuccess: async ({ types, claims, banks }) => {
        const setClaims = (commonActions.checkArray(claims?.data) && !(commonActions.checkArrayData(claims?.data))) ? [] : claims;
        //console.log(setClaims, 'setClaims_____')

        dispatch({
          type: 'SET_CLAIMS_DATA',
          claimTypes: types,
          banks,
          claims: setClaims
        });
      },
      onError: (error: any) => {

      }
    });

  const bootstrapAsync = async () => {
    dispatch({ type: 'APP_READY', userToken, userCurrentPolicy });
  }

  React.useEffect(() => {
    bootstrapAsync();
  }, []);

  const switchPolicy = useCallback(async (policy: any) => {
    authActions.updateCurrentPolicy(dispatch, policy)
  }, [state.userCurrentPolicy, state.activePolicy]);

  const logout = useCallback(() => {
    authActions.logoutUser(dispatch);
  }, [state.userData]);

  if (!state.appIsReady) {
    return (<div className="loader-box">
      <div>
        <div className="logo-inverse mb-2">
          <img src="/assets/images/logo-inverse.png" alt="Sanlam Nigeria" />
        </div>
        <div className="spinner-box">
          <img src="/assets/images/loader-light.svg" />
        </div>
      </div>
    </div>)
  }

  if (userDataLoading || userDataFetching) {
    return (<Loader />)
  }

  if (cd_error) {
    return (<>
      <LoaderHeader />
      <div className="main-container app__loading">
        <div className="page-content container">
          <NotifyMode
            //mode="full"
            subject='Seems there is a problem!'
            subjectSub="We are sorry you are experiencing this, we will keep trying."
            showSupportText={false}
            body={<>
              <p className="mb-2">
                <small className="text-secondary">If problem persist, please contact <a href="mailto:wecare@sanlam.com.ng"><strong>wecare@sanlam.com.ng</strong></a></small>
              </p>

              <small className="text-secondary">
                <strong>
                  <span onClick={() => window.location.reload()} className="cursor-pointer text-decoration-underline">Click here</span> to try again</strong>
              </small>
            </>}
          />
        </div>
      </div>
    </>)
  }

  const router = createBrowserRouter([
    {
      path: "/",
      //element: (state.userData) ? <Layout /> : <Navigate to="/get-started" />,
      element: (state.userToken) ? <Layout /> : <Navigate to="/get-started" />,
      //element: <Layout />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Home />,
            },
            {
              path: "/payment-history",
              element: <PaymentHistory />,
            },
            {
              path: "/payment",
              element: <MakePayment />,
            },
            {
              path: "/payment",
              element: <MakePayment />,
            },
            {
              path: "/make-a-claim",
              element: <MakeClaim />,
            },
            {
              path: "/claims",
              element: <Claims />,
              children: [
                {
                  children: [
                    {
                      path: "claim/:ticket_number",
                      element: <ClaimDetails />,
                    },
                  ]
                }
              ]
            },
          ]
        },
      ]
    },
    {
      path: "/get-started",
      element: <Onboard />
    }
  ]);

  return (<AppContext.Provider value={{
    dispatch,
    ...state,
    ...authActions,
    ...errorResponseActions,
    ...commonActions,
    ...dataActions,
    isSubmitting,
    setIsSubmitting,
    userDataLoading,
    userDataFetching,
    userDataError,
    claimsIsLoading,
    claimsError,
    claimsIsFetching,
    claimsRefetch,
    userDataRetry,
    switchPolicy,
    logout,
  }}>
    <RouterProvider router={router} />
  </AppContext.Provider>);
}

export default App;
import * as React from 'react';
import { BASE_API_URL, APP_URL } from '../config';

export const initialState = {
    appIsReady: false,
    appUrl: APP_URL,
    userToken: null,
    userData: null,
    userCurrentPolicy: null,
    //userTokenExp: null,
    userPolicies: null,
    userCurrentPolicyData: null,
    activePolicy: null,
    claimTypes: null,
    claims: null,
    banks: null,
};

export const AppReducer = (initialState: any, action: any) => {
    switch (action.type) {
        case "APP_READY": {
            return {
                ...initialState,
                appIsReady: true,
                userToken: action.userToken,
                userCurrentPolicy: action.userCurrentPolicy,
                // userData: action.userData,
                // userPolicies: action.userPolicies,
                // userCurrentPolicy: action.userCurrentPolicy,
            };
        }
        case "SET_APP_DATA": {
            return {
                ...initialState,
                userData: action.userData,
                userPolicies: action.userPolicies,
                userCurrentPolicyData: action.userCurrentPolicyData,
                activePolicy: action.activePolicy,
            };
        }
        case "LOGIN": {
            return {
                ...initialState,
                userToken: action.userToken,
                userData: action.userData,
                userCurrentPolicy: action.userCurrentPolicy,
                userPolicies: action.userPolicies,
                userCurrentPolicyData: action.userCurrentPolicyData,
                activePolicy: action.activePolicy,
            };
        }
        case "LOGOUT":
            return {
                ...initialState,
                userToken: null,
                userData: null,
                userPolicies: null,
                userCurrentPolicy: null,
                userCurrentPolicyData: null,
                activePolicy: null,
                claimTypes: null,
                claims: null,
            };
        case "REMOVE_USER_DATA":
            return {
                ...initialState,
                userToken: null,
                userData: null,
                userPolicies: null,
                userCurrentPolicy: null,
                userCurrentPolicyData: null,
                activePolicy: null,
                claimTypes: null,
                claims: null,
            };
        case "UPDATE_CURRENT_POLICY": {
            return {
                ...initialState,
                userCurrentPolicy: action.userCurrentPolicy,
                activePolicy: action.activePolicy,
            };
        }
        case "SET_CLAIMS_DATA": {
            return {
                ...initialState,
                claimTypes: action.claimTypes,
                claims: action.claims,
                banks: action.banks,
            };
        }
        default:
            return initialState;
    }
};
import { useMemo } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";



// Hook
export default function useRouter() {
    let [searchParams, setSearchParams] = useSearchParams();

    const params = useParams();
    let navigate = useNavigate()

    const location = useLocation();
    // const history = useHistory();
    // const match = useRouteMatch();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            navigate,
            params,
            // For convenience add push(), replace(), pathname at top level
            // push: history.push,
            // replace: history.replace,
            // pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...Object.fromEntries([...searchParams]), // Convert string to object
                //...params
            },
            setQuery: (value: any) => {
                return setSearchParams({
                    ...Object.fromEntries([...searchParams]),
                    ...value
                });
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            // match,
            location,
            // history
        };
    }, [params, /* match, */ navigate, location]);
}
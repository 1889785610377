import React from "react";

const LoaderPolicyCard: React.FC<{
    title?: string
}> = ({title = "Policy Summary"}) => {

    return (<div className="app__loading">
        <div className="card-style-1">
            <div className="card-container">
                <h3 className="title">{title}</h3>
                <div className="skl__stick_v_20 w-80 app__skeleton skl__op85 mb-3"></div>
                <div className="skl__stick_v_12 w-50 app__skeleton skl__op85 mb-1"></div>
                <span className="badge bg-dark">......</span>
            </div>
        </div>
    </div>);
}

export default LoaderPolicyCard;
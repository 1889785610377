import commonActions from './CommonActions';
import { PropsErrors } from '../../@types/errors';
import { USER_TOKEN } from '../../config';

export default {
    //errorResponse: ({ error, exclude = [], history = null, dispatch = null, forms = null }) => {
    errorResponse: async ({ dispatch, error, exclude = [], forms }: PropsErrors) => {
        if (!exclude.includes(999)) {
            //errorResponse: (error, exclude = [], history = null) => {
            let errorHeader, errMsg, errCode;

            if (error?.response?.status === undefined) {
                if (error?.message === 'Network Error') {
                    if (!navigator.onLine) {
                        errorHeader = 'Connectivity Error';
                        errMsg = 'No internet connectivity, please check and try again!';
                        errCode = `ERR_G1000`;
                    } else {
                        errorHeader = 'Error Notification';
                        errMsg = 'Can not process request, please retry or reload page!';
                        errCode = `ERR_G1002`;
                    }
                } else if (error?.code === 'ECONNABORTED') {
                    errorHeader = 'Error Notification!';
                    errMsg = 'Can not process request, please retry.';
                    errCode = `ERR_T29`;
                } else {
                    errorHeader = 'Error Notification!';
                    errMsg = 'Can not process request, please retry.';
                    errCode = `ERR_S31`;
                }

                commonActions.notify('danger', errorHeader, errMsg);
                return;
            }

            const errors = error?.response?.data;
            const status = error?.response?.status;
            const { message } = errors;

            switch (status) {
                case 0:
                    if (!exclude.includes(0)) {
                        if (error.message === 'Network Error') {
                            if (!navigator.onLine) {
                                errorHeader = 'Connectivity Error';
                                errMsg = 'No connectivity, please check your internet and try again!';
                                errCode = `ERR_G1003`;
                            } else {
                                errorHeader = 'Error Notification';
                                errMsg = 'An error occurred, please retry!';
                                errCode = `ERR_G1004`;
                            }
                        }
                    }
                    break;
                case 401:
                case 403:
                    /* TODO: Sign out */
                    if (!exclude.includes(401) && !exclude.includes(403)) {
                        //localStorage.removeItem(process.env.REACT_APP_USER_DATA);
                        localStorage.removeItem(USER_TOKEN);
                        //localStorage.removeItem(process.env.REACT_APP_USER_TOKEN_EXP);
                        dispatch({ type: 'LOGOUT' });
                        //history.replace('/login');
                        return;
                    }
                    commonActions.notify('danger', 'Authentication Error', 'Invalid email/password!');
                    return;
                case 422:
                    if (!exclude.includes(422)) {
                        let listMsg = '';
                        const listErrors = errors?.error;

                        if (listErrors) {
                            listMsg = listMsg + '<ul class="error-list">';
                            for (const error in listErrors) {
                                listMsg = listMsg + `<li>${listErrors[error]['message']}</li>`;
                                if (forms) {
                                    forms.setError(error, { type: "manual", message: listErrors[error]['message'] });
                                }
                            }

                            if (!forms) {
                                errorHeader = 'Validation Error';
                                const s = listErrors.length > 0 ? 's' : '';
                                errMsg = `The following error${s} occurred:`;
                                errMsg += listMsg;
                                listMsg = listMsg + '</ul>';
                            }
                        } else {
                            errMsg = 'Missing input, please check and try again';
                        }
                    } else {
                        return errors;
                    }

                    break;

                case 404:
                case 405:
                    if (exclude.includes(404) || exclude.includes(405)) {
                        return { status: error?.response?.status, message, error };
                    } else {
                        errorHeader = 'Error Notification';
                        //errCode = errorCode ? errorCode : null;
                        if (message) {
                            errMsg = message;
                        } else {
                            errMsg = "An error occurred, please retry!";
                            errCode = `ERR_S5102`;
                        }
                    }
                    break;
                case 400:
                    if (exclude.includes(400)) {
                        return { status: error?.response?.status, message, error };
                    } else {
                        errorHeader = 'Error Notification';
                        //errCode = errorCode ? errorCode : null;
                        if (message) {
                            errMsg = message;
                        } else {
                            errMsg = "An error occurred, please retry!";
                            errCode = `ERR_S5100`;
                        }
                    }
                    break;
                case 500:
                    if (exclude.includes(500)) {
                        return { status: error?.response?.status, message };
                    } else {
                        errorHeader = 'Error Notification';
                        //errCode = errorCode ? errorCode : null;
                        if (message) {
                            errMsg = message;
                        } else {
                            errMsg = "An error occurred, please retry!";
                            errCode = `ERR_S5100`;
                        }
                    }
                    break;
                default:
                    errorHeader = 'Error Notification';
                    errMsg = "An error occurred, please retry!";
                    errCode = `ERR_D5100D`;
            }

            if (errorHeader && errMsg)
                //alert(errorHeader, errCode ? `${errMsg} (${errCode})` : errMsg);
                commonActions.notify('danger', errorHeader, errMsg);
        }

    },
}
import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../../components/DigitFormat';
import { Btn, Input, Select } from '../../../components/Forms';
import AppContext from '../../../context/AppContext';
import LayoutContext from '../../../context/LayoutContext';
import { MakeClaimContext } from '.';

const ClaimError: React.FC = () => {
    const { currentIndex, errorMessage, setCurrentIndex } = React.useContext(MakeClaimContext);

    return (<div>
        <div className="notifications-container mt-5">
            <div className="nc-check-container error">
                <img src="/assets/images/error-white.svg" alt="" />
                <div className="pt-4">
                    <h2 className='mb-0'>Sorry</h2>
                    <p className="mb-0 text-white">Your claim request was not successful!</p>
                </div>
            </div>
            <div className="nc-content-container">
                <p className="mb-4 text-danger">
                    <strong>{errorMessage}!</strong>
                </p>
                <div>
                    <button type="button" onClick={() => setCurrentIndex(1)} className="btn theme-btn-3 btn-round mr-3 btn-effect-2">Try Again</button>
                </div>
            </div>
        </div>
    </div>)
}

export default ClaimError;
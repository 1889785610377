

export default {
    inputStyle: {
        border: 'solid 1px var(--border-color-7)',
        borderRadius: '3px',
        fontFamily: "'Titillium Web', sans-serif !important",
        color: '#495057',
    },
    inputFocusStyle:{
        border: 'solid 2px #999',
    }
}
import React from "react";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";
import Header from "./Header";


type Props = {
    //[x: string]: any;
}

const PolicyErrorPlaceholder: React.FC = () => {
    const { userDataRetry } = React.useContext(AppContext);

    return (<>
        <Header />

        <div className="main-container app__loading">
            <div className="page-content container">
                <div className="pt-50 px-3 text-center lh-1-5">
                    <img className="pb-3" src="/assets/images/shield-cancel.svg" width={70} />
                    <h3 className="text-secondary mb-1">Can Not Retrive Policy!</h3>
                    <p className="fw-600 mb-1">Policy information can not be retrived at the moment, we will keep trying.</p>

                    {/* <div>
                        <button type='button' className="btn btn-round theme-btn-1 btn-round">Try Again</button>
                    </div> */}

                    <p className="mb-2">
                        <small className="text-secondary">If issue persist, please contact <Link to="mailto:wecare@sanlam.com.ng"><strong>wecare@sanlam.com.ng</strong></Link></small>
                    </p>
                    <p>
                        <small className="text-secondary">
                            <strong>
                                <span onClick={userDataRetry} className="cursor-pointer text-decoration-underline">Click here</span> to try again</strong>
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </>);
}

export default PolicyErrorPlaceholder;
import { Button } from 'bootstrap';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../components/DigitFormat';
import { Btn, Input } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import LayoutContext from '../../context/LayoutContext';
import useRouter from '../../hooks/useRouter';
import { dowload_statement } from '../../services';
import SideSummary from '../../components/SideSummary';

const PaymentHistory: React.FC = () => {
    const { dispatch, userToken, errorResponse, activePolicy, badge, formatDate, checkArrayData } = React.useContext(AppContext);
    const { receipts } = React.useContext(LayoutContext);
    const [dl, setDl] = React.useState<Boolean>(false);

    const TransType = ({ type }: { type: string }) => {
        return (<>
            <i className={`text-${type === "CREDIT" ? 'success' : 'danger'} fa fa-circle`} aria-hidden="true"></i>
            <span className='text-secondary'>{type}</span>
        </>)
    }

    const downloadStatement = () => {
        setDl(true);
        dowload_statement(activePolicy?.policy_number)
            .then(async ({ data }) => {
                // const link = document.createElement("a");
                // link.href = `data:application/pdf;base64,${data?.pdf}`;
                // link.setAttribute("download", `statement-${data?.filename}`);
                // document.body.appendChild(link);
                // link.click();
                // // Clean up and remove the link
                // link?.parentNode?.removeChild(link);
                
                const linkSource = `data:application/pdf;base64,${data?.pdf}`;
                const downloadLink = document.createElement("a");
                const fileName = `statement-${data?.filename}`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }).catch(error => {
                console.log(error, 'error_____');
                errorResponse({ error, dispatch });
            }).then(() => {
                setDl(false);
            });
    }

    return (<div className='fade-in'>
        <div className="heading-container">
            <h1>Transaction History</h1>
            <p>See all payments made on your policy so far.</p>
        </div>

        <div className="row">
            <div className="col-md-5 mb-4 d-md-block d-none">
                <SideSummary />
            </div>
            <div className="col-md-7">

                <div className="dash mb-3">
                    {checkArrayData(receipts) ? <>
                        <div className='border-bottom pb-3 mb-3 d-flex justify-content-between'>
                            <div>
                                <Link type='button' to='/payment' className="btn btn-sm btn-round theme-btn-1 btn-effect-3">Make Payment</Link>
                            </div>
                            <div>
                                <button onClick={downloadStatement} type='button' className="btn btn-sm btn-round theme-btn-2 btn-effect-3">{dl ? 'downloading, please wait...' : 'Dowload Statement'}</button>
                            </div>
                        </div>

                        {receipts.map((receipt: any) => {
                            return (<div className='card-style-2 mb-3'>
                                <div className="card-container cc-md-none">
                                    <div>
                                        <div>{receipt?.oprReceiptNo}</div>
                                        <div className='text-icon ti-xs'>
                                            <TransType type={receipt?.oprDrCr} />
                                        </div>
                                    </div>
                                    <div><Currency value={receipt?.oprAmount} /></div>
                                    <div className='text-secondary'>{formatDate(receipt?.oprReceiptDate)}</div>
                                </div>
                                <div className="card-container cc-md-block">
                                    <div>
                                        <div>{receipt?.oprReceiptNo}</div>
                                        <div className='text-secondary'>{formatDate(receipt?.oprReceiptDate)}</div>
                                    </div>
                                    <div className='text-end'>
                                        <div className='text-icon ti-xs justify-content-end'>
                                            <TransType type={receipt?.oprDrCr} />
                                        </div>
                                        <div className='fw-bold'><Currency value={receipt?.oprAmount} /></div>
                                    </div>
                                </div>
                            </div>)
                        }
                        )}
                    </> : <>no data</>}
                </div>
            </div>
        </div>
    </div>)

}

export default PaymentHistory;
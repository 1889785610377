import React, { Dispatch } from 'react';
import moment from 'moment';
import { Store } from 'react-notifications-component';

declare type NOTIFICATION_TYPE = 'success' | 'danger' | 'info' | 'default' | 'warning';

const commonActions = {
    setPageTitle: (dispatch: any, title: string) => dispatch({ type: 'SET_PAGE_TITLE', title }),
    setQueryString: (query: any) => {
        query = Object.keys(query).length === 0 && query.constructor === Object ? '' :
            '?' + Object.keys(query).map(key => key + '=' + query[key]).join('&');
        return query;
    },
    emailRegex: (email: string) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    formatDateTime: (date: string, dateFormat: string = "ll") => moment(date).format(dateFormat),
    formatDate: (date: string, inputFormat: string = "YYYY-MM-DD") => moment(date, inputFormat).format('ll'),
    formatDateInput: (date: string, inputFormat = "DD-MM-YYYY") => moment(date, inputFormat).format('ll'),
    formatDateBr: (date: string) => <>{moment(date).format('ll')}<br />{moment(date).format('LT')}</>,

    capitalize: (str: string) => {
        if (str) {
            str = String((str).trim());
            return str
                .split(' ')
                .map(word => {
                    let string = word.toLowerCase();
                    string = string[0].toUpperCase() + string.slice(1);
                    return string;
                })
                .join(' ');
        }

        return str;
    },
    randomString: (length = 8) => {
        let result = '';
        const characters = 'abcdefghijklmnopqrstuvwxyz';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    maskPhone: (str: string) => {
        if (str && str.length > 8) {
            const prefix = (str).substring(0, 3);
            const suffix = (str).substring(-2);
            return `${prefix}_______${suffix}`
        } else {
            return str;
        }
    },
    formatPhoneCode: (dialCode: string, phone: string) => {
        phone = String(phone).trim();
        dialCode = String(dialCode).trim();
        phone = phone.substring(phone.length - 10);
        return dialCode + phone;
    },
    notify: (type: NOTIFICATION_TYPE, title: string, message: string, duration: number = 5000) => {
        //console.log(title, 'title_______')
        Store.addNotification({
            title,
            message: (<div dangerouslySetInnerHTML={{ __html: message }} />),
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                showIcon: true,
                duration,
                onScreen: true,
                pauseOnHover: true
            }
        });
    },
    checkObject: (obj: Object) => obj && Object.entries(obj).length > 0,
    checkObjectData: (obj: Object) => obj && Object.entries(obj).length > 0 && Object.values(obj).some(dt => dt),
    compareObject: (obj: any, source: any) => Object.keys(source).every(key => obj.hasOwnProperty(key) && obj[key] === source[key]),
    checkArray: (obj: Object) => obj && Array.isArray(obj),
    checkArrayData: (obj: Object) => obj && Array.isArray(obj) && obj.length > 0,
    objectToArray: async (obj: any, key = 'key', label = 'label') =>
        await Promise.all(Object.keys(obj).map((k) => ({ [key]: k, [label]: obj[k] }))),
    //check if string contains alpha
    isAlpha: (str: string) => {
        return /[a-zA-Z]/.test(str);
    },
    sizeInMB: (val: any) => (val / (1024 * 1024)).toFixed(2)
}

export default commonActions;
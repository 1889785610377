import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { OnboardContext } from '.';
import { Btn, Input } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import useRouter from '../../hooks/useRouter';

const CheckPhone: React.FC = () => {
    const { dispatch, isSubmitting, setIsSubmitting } = React.useContext(AppContext);
    const { currentIndex, setCurrentIndex, value, setValue, submitCheckPhone } = React.useContext(OnboardContext);
    const { handleSubmit, formState: { errors }, reset, register, getValues } = useForm<IFormInputs>();
    const router = useRouter();
    let { from } = router.location.state || { from: { pathname: "/account" } };

    const onSubmit: SubmitHandler<IFormInputs> = (data) => {
        submitCheckPhone(data);
    }

    return (<>
        {currentIndex === 1 && (<form onSubmit={handleSubmit(onSubmit)} className="fade-in">

            <div className="form-group">
                <Input
                    label={<div className='mb-3 lh-1-6 text-secondary'>
                        Please enter your phone number to get started.<br />
                        <strong>
                            This should be the phone number use to register your policies.
                        </strong>
                    </div>}
                    name="phone_number" placeholder="Phone Number (e.g 08023237743)*" register={register}
                    errors={errors}
                    validate={{
                        required: "Phone number is required!"
                    }}
                />
            </div>
            <div className="btn-wrapper d-grid mt-0">
                <Btn submitting={isSubmitting} label="CONTINUE" />
            </div>

        </form>)}
    </>)

}

export default CheckPhone;
import React from "react";
import LayoutContext from "../context/LayoutContext";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";
import Nav from "./nav";
import useRouter from "../hooks/useRouter";


type Props = {
    //[x: string]: any;
}

const Header: React.FC = () => {
    const { checkArrayData, userPolicies, userCurrentPolicyData, activePolicy, badge, switchPolicy, logout } = React.useContext(AppContext);
    const { navigate } = useRouter();

    const onSwithPolicy = (policy_number: string) => {
        switchPolicy(policy_number);
        navigate('/');
    }

    return (<>
        <div className="side-container" id="side-container">
            <div className="logo">
                <Link to="/">
                    <img src="/assets/images/logo.png" />
                </Link>
            </div>
            <ul className="sidebar-menu">
                <li><Link to="/">Policy</Link></li>
                <li><Link to="/payment">Make Payment</Link></li>
                <li><Link to="/payment-history">Payment History</Link></li>
                <li><Link to="/claims">Claims</Link></li>
                <li><Link to="/make-a-claim">Make Claim</Link></li>
            </ul>
        </div>

        <header id="header">
            <div className="header-container">
                <div className="hamburger" id="menu-hamburger">
                    <img src="/assets/images/hamburger-3.svg" />
                </div>

                <div className="nav-container">
                    <div className="logo">
                        <Link to="/">
                            <img src="/assets/images/logo.png" />
                        </Link>
                    </div>
                </div>

                <div className="header-menu d-none d-md-block">
                    <Nav />
                </div>

                <nav className="header-right-container navbar">
                    <div className="btn-group">
                        <a type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <div className="user-avatar">
                                <img src="/assets/images/shield.svg" alt="" />
                            </div>
                            <div className="hrc-content">
                                <div>{activePolicy?.policy_number}</div>
                                <div>{activePolicy?.description}</div>
                            </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                            <div className="dropdown-item dropdown-item-main">
                                <div className="user-avatar">
                                    <img src="/assets/images/shield.svg" alt="" />
                                </div>
                                <div className="mt-3">
                                    <div className="dpd-1">{activePolicy?.policy_number}</div>
                                    <div className="dpd-2">{activePolicy?.description}</div>
                                    <div className="pt-1">
                                        {userCurrentPolicyData?.details?.polStatusDesc && badge(userCurrentPolicyData?.details?.polStatusDesc)}
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown-item-container ltn__scrollbar">
                                {checkArrayData(userPolicies) && userPolicies.length > 1 && userPolicies.filter((ft: any) => ft.policy_number !== activePolicy?.policy_number).map((policy: any) => {
                                    return (<button key={policy?.policy_number} onClick={() => onSwithPolicy(policy)} className="dropdown-item" type="button">
                                        <div className="dpd-1">{policy?.policy_number}</div>
                                        <div className="dpd-2">{policy?.description}</div>
                                    </button>)
                                })}
                            </div>

                            <button onClick={logout} className="dropdown-item dropdown-item-remove" type="button">
                                Sign Out
                            </button>
                        </div>
                    </div>
                </nav>

            </div>
        </header>

    </>);
}

export default Header;
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';
import { Btn, Input } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import ClaimsContext from '../../context/ClaimsContext';
import LayoutContext from '../../context/LayoutContext';
import useRouter from '../../hooks/useRouter';
import NotifyMode from '../../components/NotifyMode';
import LoaderPolicyCard from '../../components/loader/LoaderPolicyCard';
import SideSummary from '../../components/SideSummary';
import LoaderClaim from '../../components/loader/LoaderClaim';

const Claims: React.FC = () => {
    const { badge, formatDate, claimsIsLoading, claimsError, claimsIsFetching, claimsRefetch, claims, checkArrayData, activePolicy } = React.useContext(AppContext);
    const { claimDetails, polStatusDesc } = React.useContext(LayoutContext);
    const { params } = useRouter();

    return (<>
        <div className="heading-container">
            <h1>{params.ticket_number ? 'Claim Details' : 'Claims'}</h1>
            <p>View all claims and make claim on policies.</p>
        </div>

        {claimsIsLoading ? <LoaderClaim /> : <>
            {claimsError ? <>
                <NotifyMode
                    subject='Seems there is a problem!'
                    subjectSub="Can not get claims info at the moment, we will keep trying."
                    supportText='If issue persist, please contact'
                    onClick={claimsRefetch}
                />
            </> : <>
                {!checkArrayData(claims) ? <>
                    <NotifyMode
                        subject='No claim found!'
                        subjectSub="There is no claim for this policy."
                        supportText='For questions regarding claims, please contact'
                        //onClick={claimsRefetch}
                        body={<NavLink role="button" to={`/make-a-claim`}
                            className="btn btn-round btn-effect-1 btn-dark my-2">Make A Claim</NavLink>}
                    />

                </> : (<div className="row">
                    <div className="col-md-5 mb-3">
                        <SideSummary />
                    </div>
                    <div className="col-md-7">
                        <div className='border-bottom pb-3 mb-3 d-flex justify-content-between'>
                            <div>
                                <div className='text-icon me-2'>
                                    {params?.ticket_number && (<Link to={'/claims'}>
                                        <small>
                                            <i className="fa fa-arrow-left"></i> <strong>Back to Claims</strong>
                                        </small>
                                    </Link>)}
                                </div>
                                {/* <Link type='button' to='/make-claim' className="btn btn-sm btn-round theme-btn-2">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                </Link> */}

                            </div>
                            <div>
                                <Link type='button' to='/make-a-claim' className="btn btn-sm btn-round theme-btn-1 btn-effect-3">Make New Claim</Link>
                            </div>
                        </div>

                        {params.ticket_number ? (<Outlet />) : (<>
                            {claims.map((claim: any) => {
                                return (<NavLink key={claim?.ticket_number} to={`claim/${claim?.ticket_number}`} className="card-style-2 mb-3 cursor-pointer">
                                    <div className="card-container cc-no-align">
                                        <div>
                                            <div>{claim?.ticket_number}</div>
                                            <div className="text-secondary">
                                                {claim?.ticket_type?.name}
                                            </div>
                                        </div>
                                        <div>
                                            <small className="text-secondary">
                                                {moment(claim?.createdAt).fromNow()}
                                            </small>
                                        </div>
                                    </div>
                                </NavLink>);
                            })}
                        </>)}
                    </div>
                </div>)}
            </>}
        </>}
    </>)

}

export default Claims;
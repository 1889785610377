import React from "react";
import LoaderPolicyCard from "./LoaderPolicyCard";

const LoaderClaim: React.FC = () => {

    return (<div className="row">
        <div className="col-md-5 mb-3">
            <LoaderPolicyCard title='' />
        </div>
        <div className="col-md-7">
            <div className='border-bottom pb-3 mb-3 d-flex justify-content-between'>
                <div className="wf-100 skl__h15 app__skeleton" />
                <div className="wf-100 skl__h15 app__skeleton" />
            </div>
            <div>
                <div className="card-style-2 app__skeleton skl__h60 mb-3" />
                <div className="card-style-2 app__skeleton skl__h60 mb-3" />
                <div className="card-style-2 app__skeleton skl__h60 mb-3" />
            </div>
        </div>
    </div>);
}

export default LoaderClaim;
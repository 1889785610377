import React from "react";

const LoaderHeader: React.FC = () => {

    return (<header id="header" className="app__loading">
        <div className="header-container">
            <div className="hamburger">
                <img src="/assets/images/hamburger-3.svg" />
            </div>

            <div className="nav-container">
                <div className="logo">
                    <img src="/assets/images/logo.png" />
                </div>
            </div>

            <div className="header-menu d-none d-md-block">
                <nav>
                    <div className="ltn__main-menu">
                        <ul>
                            <li className="menu-icon">
                                <span className="app__skeleton" />
                            </li>
                            <li className="menu-icon">
                                <span className="app__skeleton" />
                            </li>
                            <li className="menu-icon">
                                <span className="app__skeleton" />
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>

            <nav className="header-right-container">
                <div className="btn-group">
                    <span>
                        <div className="user-avatar app__skeleton">
                            <img src="/assets/images/shield.svg" alt="" />
                        </div>
                        <div className="hrc-content">
                            <div className="skeleton__stick_v_sm w-75 app__skeleton mb-2" />
                            <div className="skeleton__stick_v_sm w-auto app__skeleton" />
                        </div>
                    </span>
                </div>
            </nav>

        </div>
    </header>);
}

export default LoaderHeader;
//import * as dotenv from "dotenv";
//dotenv.config();

export const APP_URL = process.env.REACT_APP_APP_URL;
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const CONNECTION_TIMEOUT = Number(process.env.REACT_APP_CONNECTION_TIMEOUT);
export const USER_TOKEN: any = process.env.REACT_APP_USER_TOKEN;
export const CURRENT_POLICY: any = process.env.REACT_APP_CURRENT_POLICY;


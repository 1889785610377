import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { OnboardContext } from '.';
import { Btn } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import useRouter from '../../hooks/useRouter';



const ValidationOption: React.FC = () => {
    const { isSubmitting } = React.useContext(AppContext);
    const { currentIndex, setCurrentIndex, submitRequestOtp, value } = React.useContext(OnboardContext);
    const { handleSubmit, register } = useForm<IFormInputs>();
    const router = useRouter();

    const onSubmit: SubmitHandler<IFormInputs> = ({ validation_type }) => {
        submitRequestOtp(validation_type)
    }

    return (<>
        {currentIndex === 2 && (<form onSubmit={handleSubmit(onSubmit)} className="fade-in">
            <div className='label mb-2'>
                Please select how you want to receive One Time password(OTP)
            </div>
            <div className='mb-4'>
                <div className="form-check">
                    <input className="form-check-input" type="radio"
                        id="phone" defaultChecked value={'phone'}
                        {...register('validation_type')}
                    />
                    <label className="form-check-label" htmlFor="phone">
                        {value?.masked_phone}
                    </label>
                </div>

                {value?.masked_email && (<div className="form-check">
                    <input className="form-check-input" type="radio"
                        id="email" value={'email'}
                        {...register('validation_type')}
                    />
                    <label className="form-check-label" htmlFor="email">
                        {value?.masked_email}
                    </label>
                </div>)}
            </div>
            <div className="btn-wrapper d-grid mt-0 mb-3">
                <Btn submitting={isSubmitting} label="SEND OTP" />
            </div>
            <div>
                <div onClick={() => setCurrentIndex(1)} className='fw-600 cursor-pointer ltn__secondary-color'>
                    <small>Change Phone number</small>
                </div>
            </div>

        </form>)}
    </>)

}

export default ValidationOption;
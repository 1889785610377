import React from "react";
import { IMAGE_URL } from "../../config";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LayoutContext from "../../context/LayoutContext";
import { LinkDetails } from "../Links";
import AppContext from "../../context/AppContext";
import { Currency } from "../DigitFormat";
import LoaderHeader from "./LoaderHeader";
import LoaderDash from "./LoaderDash";
import LoaderCard from "./LoaderCard";


type Props = {
    //[x: string]: any;
}

const Loader: React.FC = () => {
    const { badge } = React.useContext(AppContext);
    const { activePolicy, polStatusDesc, totalPremium, totalPremiumPaid, totalPremiumDue } = React.useContext(LayoutContext)

    return (<>
        <LoaderHeader />

        <div className="main-container app__loading">
            <div className="page-content container">
                <div className="page__dashboard">
                    <LoaderDash />


                    <div className="row">
                        <div className="col-md-6">
                            <LoaderCard />
                        </div>
                        <div className="col-md-6">
                            <LoaderCard />
                        </div>
                        <div className="col-md-6">
                            <LoaderCard />
                        </div>
                        <div className="col-md-6">
                            <LoaderCard />
                        </div>
                    </div>


                </div>
            </div>
        </div>

    </>);
}

export default Loader;
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Btn, Input } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import useRouter from '../../hooks/useRouter';
import SideSummary from '../../components/SideSummary';
import LayoutContext from '../../context/LayoutContext';
import { Currency, NumberFormat } from '../../components/DigitFormat';
import { payment_init, payment_verify } from '../../services';
import { APP_URL } from '../../config';
import { useQuery } from 'react-query';
import { ErrorMessage } from '@hookform/error-message';

const MakePayment: React.FC = () => {
    const { dispatch, userToken, errorResponse, capitalize, isSubmitting, setIsSubmitting } = React.useContext(AppContext);
    const { premium, activePolicy, policyFreq } = React.useContext(LayoutContext);

    const [formID] = React.useState('payment-form');
    const [payment_method, set_payment_method] = React.useState(null);
    const [currentIndex, setCurrentIndex] = React.useState<number>(1);

    const { query } = useRouter();
    const [reference] = React.useState(query?.reference || query?.tx_ref);
 

    const { handleSubmit, clearErrors, formState: { errors }, setError, register, getValues, setValue, watch } = useForm<IFormInputs>({
        criteriaMode: "all",
        defaultValues: {
            recurring: '1'
        }
    });

    const recurring = watch('recurring');
    const amount = watch('amount');

    const onContinue = React.useCallback(() => {
        clearErrors(["payment_method", "amount"])
        if (!payment_method) {
            setError("payment_method", {
                type: "manual",
                message: "Please select payment method!"
            });

            return;
        }

        if (!amount) {
            setError("amount", {
                type: "manual",
                message: "Amount is required!"
                /* types: {
                    required: "Amount is required!",
                }, */
            });

            return;
        }

        setCurrentIndex(2);
    }, [payment_method, amount]);

    const onSubmit: SubmitHandler<IFormInputs> =
        React.useCallback(({ amount, recurring, customer_email }) => {
            //console.log(payment_method, 'payment_method__3333____')

            setIsSubmitting(true);
            let postData = {};
            postData = {
                policy_number: activePolicy.policy_number,
                amount,
                payment_method,
                payment_type: "one-time",
                customer_email: customer_email ? customer_email : activePolicy.email_address,
                confirmation_source: APP_URL + 'payment'
            }

            if (recurring === "2") {
                postData = {
                    ...postData,
                    payment_type: "recurring",
                    interval: (policyFreq).toLowerCase()
                }
            }

            console.log(postData, 'postData_____')

            payment_init(postData).then(({ data }) => {
                //console.log(authorization_url, 'response______');
                window.location.replace(data.payment_url);
            }).catch((error: any) => {
                //console.log(error, 'error_____');
                errorResponse({ error, dispatch });
            }).then(() => {
                setIsSubmitting(false);
            });
        }, [payment_method]);

    React.useEffect(() => {
        if (premium > 0) {
            setValue('amount', premium)
        }
    }, [premium]);

    const onSetPaymentMethod = (method: any) => {
        set_payment_method(method);
        setValue('recurring', '1');
        clearErrors(["payment_method"]);
    }

    const recurringNote = () => {
        return (<div className="mt-2 action-box">
            <p>
                You have chosen <strong>Auto Renewal</strong> payment option to
                pay your policy number <strong>{activePolicy?.policy_number}</strong> premium.
            </p>

            <p>
                This means your policy premium, <strong><Currency value={premium} /></strong> will be
                paid <strong>{policyFreq}</strong> directly from your debit/credit
                card with no additional effort on your part.
            </p>
            <p>
                <strong>You can cancel this subscription at anytime.</strong>
            </p>
        </div>)
    }


    const {
        isLoading: verifyIsLoading,
        isError: verifyIsError,
        error: verifyError,
        isFetching: verifyIsFetching,
        isSuccess: verifyIsSuccess,
        data: verifyData
    } = useQuery(['verify-payment', reference], () => payment_verify(reference), {
        retry: 0,
        enabled: Boolean(reference),
        //keepPreviousData: true,
        //placeholderData: (prev: any) => prev,
        //staleTime: 300000, // 5 minutes
        onSuccess: async () => {
            console.log('OK_____')
        },
        onError: (error: any) => {

        }
    });

    React.useEffect(() => {
        console.log({ verifyError, verifyData }, 'dtt_____');
    }, [verifyError, verifyData]);

    if (verifyIsLoading) {
        return <>
            <div className="notifications-container">
                <div className="nc-check-container dark py-5">
                    <img src="/assets/images/loader-light.svg" alt="" />
                    <h4 className='pt-4 mb-0'>Let's verify your payment</h4>
                </div>
                <div className="nc-content-container py-5">
                    <div className='py-3'>
                        <strong>Please wait while we verify payment...</strong>
                    </div>
                </div>
            </div>
        </>
    }

    if (verifyIsError) {
        return <>

            <div className="notifications-container">
                <div className="nc-check-container error">
                    <img src="/assets/images/error-white.svg" alt="" />
                    <div className="pt-4">
                        <h2 className='mb-0'>Sorry</h2>
                        <p className="mb-0 text-white">Payment was not successful!</p>
                    </div>
                </div>
                <div className="nc-content-container">
                    <p className="mb-4 text-danger">
                        <strong>{verifyError?.response?.data?.message}</strong>
                    </p>
                    <div>

                        <NavLink role="button" to={`/payment-history`}
                            className="btn btn-round btn-dark">View Payment History</NavLink>
                    </div>
                </div>
            </div>
        </>
    }

    if (verifyIsSuccess) {
        return <>
            <div className="notifications-container">
                <div className="nc-check-container">
                    <img src="/assets/images/checked-white.svg" alt="" />
                    <div className="pt-4">
                        <h2>Awesome</h2>
                        <p className="mb-0 text-white">Your payment is successful!</p>
                    </div>
                </div>
                <div className="nc-content-container">
                    <p className="mb-1">Your premium payment is successful, please note the transaction reference number below </p>
                    <p className="text-dark">
                        <strong>{reference}</strong>
                    </p>
                    <div>
                        {/*  <NavLink role="button" to={`/policy`}
                            className="btn theme-btn-2 btn-sm btn-round mr-3">Continue</NavLink> */}

                        <NavLink role="button" to={`/payment-history`}
                            className="btn btn-round btn-success">View Payment History</NavLink>
                    </div>
                </div>
            </div>
        </>
    }

    return (<>
        <div className="heading-container">
            <h1>Make Payment</h1>
            <p>Make payment on all your policies.</p>
        </div>

        <div className="row">
            <div className="col-md-5 mb-4 d-md-block d-none">
                <SideSummary />
            </div>
            <div className="col-md-7">
                <form id={formID} onSubmit={handleSubmit(onSubmit)} className="fade-in">



                    {/* END /Select Payment option */}
                    {/* Select Payment option */}
                    {currentIndex === 1 && (<div className="fade-in">
                        <div className="form-group-block pt-0">
                            <div className="form-group mb-0">
                                <div className="title-v-container">
                                    <h4>Payment Details</h4>
                                    <p>How much do you want to pay?</p>
                                </div>
                                <Input name="amount" type='number' placeholder="Enter amount" register={register}
                                    errors={errors}
                                    validate={{
                                        required: "Amount is required!"
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group-block pt-4">
                            <div className="title-v-container">
                                <h4>Payment Channel</h4>
                                <p className="mb-1">What's your preffered channel of payment?</p>
                            </div>

                            <div>
                                <div className={`select-btn-group${payment_method === 'paystack' ? ' active' : ''}`}>

                                    <div className="sbg-container" onClick={() => onSetPaymentMethod('paystack')}>
                                        <div className="img-box">
                                            <img src="/assets/images/paystack.png" alt="" />
                                        </div>
                                        <div className="sbg-content">
                                            <h5>Paystack</h5>
                                            <p>Pay with Mastercard, Visa, Verve, USSD</p>
                                        </div>
                                    </div>
                                    {payment_method === 'paystack' && (<div className="sbg-container">
                                        <div className="sbg-content">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" {...register('recurring')}
                                                    id="oneoff_1" value={`1`} />
                                                <label className="form-check-label font-size-14"
                                                    htmlFor="oneoff_1">
                                                    One Off Payment
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" {...register('recurring')}
                                                    id="recurring_1" value={`2`} />
                                                <label className="form-check-label  font-size-14"
                                                    htmlFor="recurring_1">
                                                    Setup Recurring Payment
                                                </label>
                                            </div>
                                            {recurring === '2' && recurringNote()}
                                        </div>
                                    </div>)}
                                </div>

                                <div className={`mb-0 select-btn-group${payment_method === 'flutterwave' ? ' active' : ''}`}>
                                    <div className="sbg-container" onClick={() => onSetPaymentMethod('flutterwave')}>
                                        <div className="img-box">
                                            <img src="/assets/images/flutterwave.png" alt="" />
                                        </div>
                                        <div className="sbg-content">
                                            <h5>Flutterwave</h5>
                                            <p>Pay with Mastercard, Visa, Verve, USSD</p>
                                        </div>
                                    </div>


                                    {payment_method === 'flutterwave' && (<div className="sbg-container">
                                        <div className="sbg-content">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" {...register('recurring')}
                                                    id="oneoff_2" value={`1`} />
                                                <label className="form-check-label font-size-14"
                                                    htmlFor="oneoff_2">
                                                    One Off Payment
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" {...register('recurring')}
                                                    id="recurring_2" value={`2`} />
                                                <label className="form-check-label  font-size-14"
                                                    htmlFor="recurring_2">
                                                    Setup Recurring Payment
                                                </label>
                                            </div>
                                            {recurring === '2' && recurringNote()}
                                        </div>
                                    </div>)}
                                </div>
                                <ErrorMessage
                                    errors={errors}
                                    name="payment_method"
                                    render={({ message }) => <span className="text-error">{message}</span>}
                                />
                            </div>
                        </div>

                        <div className='pt-3 text-end'>
                            <button onClick={onContinue} type='button' className="btn btn-round theme-btn-1">Continue</button>
                        </div>
                    </div>)}
                    {/* END /Select Payment option */}

                    {/* Payment summary */}
                    {currentIndex === 2 && (<div className="fade-in">
                        <div className="form-group-block pt-0 pb-3">
                            <div className="form-group mb-0">
                                <div className="title-v-container">
                                    <h4>Payment Summary</h4>
                                    <p>Please review payment details below before proceeding</p>
                                </div>

                            </div>
                        </div>

                        <div className="form-group-block pt-3 pb-3">
                            <div className="smy-block">
                                <div className="smy-label">Total Amount Payable</div>
                                <div className="smy-text">
                                    <h1><Currency value={getValues('amount')} /></h1>
                                </div>
                            </div>

                            <div className="smy-block">
                                <div className="smy-label">Transaction Charge</div>
                                <div className="smy-text">
                                    To be calculated on payment page
                                </div>
                            </div>

                        </div>

                        <div className="form-group-block pt-4 pb-3">
                            <div>
                                <div className="select-btn-group active">
                                    <div className="bgt-container">
                                        <div className="img-box">
                                            <img src={`/assets/images/${payment_method}.png`} alt="" />
                                        </div>
                                        <div>
                                            <h5>{capitalize(payment_method)}</h5>
                                            <p>You chose {(payment_method)} as preffered payment option.</p>
                                            {recurring === '2' && (<p className="font-size-13">You also chose <strong className="text-dark"><Currency value={premium} /> monthly auto payment</strong> option.</p>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!activePolicy?.email_address && (<div className="form-group-block pt-4 pb-3">
                            <div className="form-group mb-0">
                                <div className="title-v-container">
                                    <h6 className='mb-1'>Email address is required to make payment, please provide your email below</h6>
                                </div>
                                <Input name="customer_email" type='email' placeholder="Enter email address" register={register}
                                    errors={errors}
                                    validate={{
                                        required: "Email address is required!"
                                    }}
                                />
                            </div>
                        </div>)}

                        <div className='pt-3 text-end'>
                            <button onClick={() => setCurrentIndex(1)} type='button' className="btn btn-round theme-btn-3">Go Back</button>

                            <Btn submitting={isSubmitting} className="btn btn-round theme-btn-1"
                                label={<>Pay <Currency value={getValues('amount')} /> Now</>}
                            />
                        </div>
                    </div>)}



                </form>
            </div>
        </div>
    </>)

}

export default MakePayment;
import React from "react";
import AppContext from "../context/AppContext";
import { Link } from "react-router-dom";
import Header from "../layout/Header";

type Props = {
    //[x: string]: any;
    subject: string,
    subjectSub: string,
    //retry?: boolean,
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    supportText?: string,
    showSupportText?: Boolean,
    body?: React.ReactNode
    mode?: 'full' | 'default'
}

const NotifyMode: React.FC<Props> = ({
    subject,
    subjectSub,
    //retry = true,
    onClick,
    showSupportText = true,
    supportText = "For more information and support, please contact ",
    body,
    mode = 'default'
}) => {

    const Body = () => {
        return (<div className="pt-50 px-3 text-center lh-1-5">
            <img className="pb-3" src="/assets/images/shield-cancel.svg" width={70} />
            <h3 className="text-secondary mb-1">{subject}</h3>
            <p className="fw-600 mb-1">{subjectSub}</p>

            {body && <>{body}</>}

            {showSupportText && <p className="mb-2">
                <small className="text-secondary">{supportText} <Link to="mailto:wecare@sanlam.com.ng"><strong>wecare@sanlam.com.ng</strong></Link></small>
            </p>}

            {onClick && (<p>
                <small className="text-secondary">
                    <strong>
                        <span onClick={onClick} className="cursor-pointer text-decoration-underline">Click here</span> to try again</strong>
                </small>
            </p>)}
        </div>)
    }

    return (<>
        {mode === 'full' ? <>
            <Header />
            <div className="main-container app__loading">
                <div className="page-content container">
                    <Body />
                </div>
            </div>
        </> : <Body />}

    </>);
}

export default NotifyMode;
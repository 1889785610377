import React from "react";
import LoaderPolicyCard from "./LoaderPolicyCard";

const LoaderDash: React.FC = () => {

    return (<div className="row mb-2 dash-v-block">
        <div className="col-md-6">
            <LoaderPolicyCard />
        </div>

        <div className="col-md-6 d-md-block d-none">
            <LoaderPolicyCard />
        </div>
    </div>);
}

export default LoaderDash;
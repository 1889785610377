import * as React from 'react';
import { OnboardContext } from '.';
import PinInput from 'react-pin-input';
import pinInputCss from './pin-input.css';
import Timer from '../../components/Timer';
import AppContext from '../../context/AppContext';



const ValidateOtp: React.FC = () => {
    const { submitting, setIsSubmitting } = React.useContext(AppContext);
    const { currentIndex, setCurrentIndex, submitValidateOtp, value } = React.useContext(OnboardContext);
    const inputRef = React.useRef<PinInput>(null);

    const resendOtp = () => {
        console.log("TODO: //resendOtp")
    };

    return (<>
        {currentIndex === 3 && (<div>
            <div className='label mb-2'>
                <div className='text-dark'>Enter OTP</div>
                <small>
                    a 4 digit code was sent to <span className='fw-600'>
                        {value?.validation_type === "phone" ? value?.masked_phone : value?.masked_email}
                    </span>
                </small>
            </div>
            <PinInput
                length={4}
                initialValue=""
                onChange={(value, index) => { }}
                type="numeric"
                inputMode="number"
                style={{ padding: '10px 0 0px 0' }}
                inputStyle={pinInputCss.inputStyle}
                inputFocusStyle={pinInputCss.inputFocusStyle}
                onComplete={(value, index) => submitValidateOtp(value)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                ref={inputRef}
                disabled={submitting}
            />

            <div>
                {submitting ? <>
                validating, please wait...
                </> : <div className='mb-3 fs-12 mt-1 fw-600 cursor-pointer ltn__secondary-color'>
                    <Timer onClick={resendOtp} />
                </div>}

                <div onClick={() => setCurrentIndex(2)} className='mt-3 fw-600 cursor-pointer text-secondary'>
                    <small>Change OTP validation option</small>
                </div>
            </div>
        </div>)}
    </>)

}

export default ValidateOtp;
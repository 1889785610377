import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../../components/DigitFormat';
import { Btn, Input, Select } from '../../../components/Forms';
import AppContext from '../../../context/AppContext';
import LayoutContext from '../../../context/LayoutContext';
import { MakeClaimContext } from '.';

const ClaimSummary: React.FC = () => {
    const { claimTypes, isSubmitting, activePolicy, checkArrayData, claims, banks } = React.useContext(AppContext);
    const { } = React.useContext(LayoutContext);
    const { values, currentIndex, documents, onClaimSubmit, setCurrentIndex } = React.useContext(MakeClaimContext);

    const onSubmit = () => {
        console.log(values, 'values____')
        onClaimSubmit(values)
    }

    return (<>{currentIndex === 2 && (<div className="fade-in">

        <div>
            <div className="title-v-container mb-4 lh-1">
                <h2 className='mb-0'>Claim Summary</h2>
                <p>Please review claim information details below.</p>
            </div>

            <div className="mb-4">
                <div className="title-v-container mb-3 lh-1 border-bottom padded">
                    <h5 className='mb-0 text-secondary'>Claim Information</h5>
                    <p className='text-secondary'>
                        <small>Claim type, policy info..</small>
                    </p>
                </div>

                <div className="smy-ssb left">
                    <div className="smy-block">
                        <div className="smy-label">Policy</div>
                        <div className="smy-text">{activePolicy?.policy_number} ({activePolicy?.description})</div>
                    </div>
                </div>

                <div className="smy-ssb left">
                    <div className="smy-block">
                        <div className="smy-label">Claim Type</div>
                        <div className="smy-text">{claimTypes.find((dt: any) => dt.key === values?.claim_type)?.name}</div>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="title-v-container mb-3 lh-1 border-bottom padded">
                    <h5 className='mb-0 text-secondary'>Payment Information</h5>
                    <p className='text-secondary'>
                        <small>Bank info, account info..</small>
                    </p>
                </div>



                {/* <div className="smy-ssb smy-ssb-sm">
                    <div className="smy-block">
                        <div className="smy-label">Account Bank Name</div>
                        <div className="smy-text">Gtbank</div>
                    </div>

                    <div className="smy-block">
                        <div className="smy-label">Account Type</div>
                        <div className="smy-text">Savings</div>
                    </div>
                </div> */}

                <div className="smy-ssb left">
                    <div className="smy-block">
                        <div className="smy-label">Account Bank</div>
                        <div className="smy-text">
                            {banks.find((dt: any) => dt.slug === values?.bank_name)?.name} ({values?.acc_type === 'savings' ? 'Savings Account' : 'Current Account'})
                        </div>
                    </div>
                </div>

                <div className="smy-ssb left">
                    <div className="smy-block">
                        <div className="smy-label">Account Info</div>
                        <div className="smy-text">{activePolicy?.full_name} ({values?.acc_num})</div>
                    </div>
                </div>

                {/* <div className="smy-ssb smy-ssb-sm">
                    <div className="smy-block">
                        <div className="smy-label">Account Name</div>
                        <div className="smy-text">Samuel Oluwafemi Fadero</div>
                    </div>

                    <div className="smy-block">
                        <div className="smy-label">Account Number</div>
                        <div className="smy-text">3008492135</div>
                    </div>
                </div> */}
            </div>


            <div className="mb-4">
                <div className="title-v-container mb-3 lh-1 border-bottom padded">
                    <h5 className='mb-0 text-secondary'>Document</h5>
                    <p className='text-secondary'>
                        <small>Image, pdf..</small>
                    </p>
                </div>

                <div id='upload-file-list-box' className='upload-file-list-box'>
                    {checkArrayData(documents) && documents.map((file: any) => {
                        return (<div className='upload-file-list' key={file.id}>
                            <div className="smy-ssb">
                                <div className='lh-1-3'>
                                    <div className='upload-file-item'>
                                        <img src='/assets/images/checked.svg' />
                                        <span>{file.file_name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>

            </div>

        </div>

        <div className='pt-3 text-end border-top'>
            <button disabled={isSubmitting} onClick={() => setCurrentIndex(1)} type='button' className="btn btn-round theme-btn-3 btn-effect-2">Go Back</button>

            <Btn
                onClick={onSubmit}
                submittingSize={20}
                submittingText="saving, please wait..."
                type={`button`}
                submitting={isSubmitting}
                className="btn btn-round theme-btn-1 btn-effect-2"
                label={<>Submit</>}
            />
        </div>

    </div>)}</>)

}

export default ClaimSummary;
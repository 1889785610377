import * as React from 'react';
import { createContext, useCallback, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import AppContext from '../../context/AppContext';
import useRouter from '../../hooks/useRouter';
import { auth_check_phone, auth_request_otp, auth_validate_otp } from '../../services';
import CheckPhone from './check-phone';
import ValidateOtp from './validate-otp';
import SelectPhone from './validation-option';

export const OnboardContext = createContext<PropsAppContext>({});

type OnboardProps = {
    phone_number?: string | null,
    session_id?: string | null,
    masked_phone?: string | null,
    masked_email?: string | null,
    validation_type?: string | null,
};

const Onboard = () => {
    const { dispatch, errorResponse, setIsSubmitting, setUserData, extractPolicyData, userToken } = React.useContext(AppContext);
    const router = useRouter();

    const [currentIndex, setCurrentIndex] = useState<number>(1);
    const [phones_list, set_phones_list] = useState<any[]>([]);
    const [value, setValue] = useState<OnboardProps>({})

    const submitCheckPhone = useCallback(async (data: OnboardProps) => {
        setIsSubmitting(true);
        setValue({ ...value, ...data });
        auth_check_phone(data).then(({ session_id, masked_phone, masked_email }) => {
            setValue({ ...value, session_id, masked_phone, masked_email })
            setCurrentIndex(2);
        }).catch(error => {
            console.log(error, 'error_____');
            errorResponse({ error, dispatch });
        }).then(() => {
            setIsSubmitting(false)
        });
    }, [value]);

    const submitRequestOtp = useCallback(async (validation_type: string) => {
        setIsSubmitting(true);
        setValue({ ...value, validation_type });
        const postData = { session_id: value.session_id, validation_type }
        auth_request_otp(postData).then(() => {
            setCurrentIndex(3);
        }).catch(error => {
            console.log(error, 'error_____');
            errorResponse({ error, dispatch });
        }).then(() => {
            setIsSubmitting(false);
        });
    }, [value]);

    const submitValidateOtp = useCallback(async (otp: string) => {
        setIsSubmitting(true);
        const postData = { session_id: value.session_id, otp }
        auth_validate_otp(postData).then(({ token, data: { userData, policyData, userCurrentPolicyData, activePolicy } }) => {
            //set user data
            setUserData(dispatch, token, userData, policyData, activePolicy.policy_number, userCurrentPolicyData, activePolicy);

        }).catch(error => {
            //console.log(error, 'error_____');
            errorResponse({ error, dispatch });
        }).then(() => {
            setIsSubmitting(false);
        });
    }, [value]);

    React.useEffect(() => {
        console.log(value, 'state.value_____');
    }, [value]);

    React.useEffect(() => {
        return () => setValue({})
    }, []);

    React.useEffect(() => {
        if (userToken) {
            router.navigate('/')
        }
    }, [userToken]);

    return (<OnboardContext.Provider value={{
        currentIndex,
        setCurrentIndex,
        value,
        setValue,
        submitCheckPhone,
        submitRequestOtp,
        submitValidateOtp
    }}>
        <div className='row landing-main'>
            <div className='col-md-7 landing-content-box'>
                <div className="lcb__content">
                    <h1 className="mb-1">Customer Self Service Portal</h1>
                    <p>This portal helps customers to make claims,<br />make payments and so much more...</p>
                    <img src="/assets/images/ico-messaging.png" alt="" />
                </div>
            </div>
            <div className='col-md-5 landing-form-box'>
                <div className="lfb__content">
                    <div className="lfb__logo">
                        <img src="/assets/images/logo.svg" alt="" />
                    </div>
                    <div className='lfb__steps'>
                        Step {currentIndex}/3
                    </div>
                    <div>
                        <CheckPhone />
                        <SelectPhone />
                        <ValidateOtp />
                    </div>
                </div>
            </div>
        </div>
    </OnboardContext.Provider>)

}

export default Onboard;
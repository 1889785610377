import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../components/DigitFormat';
import { Btn, Input } from '../../components/Forms';
import AppContext from '../../context/AppContext';
import ClaimsContext from '../../context/ClaimsContext';
import LayoutContext from '../../context/LayoutContext';
import useRouter from '../../hooks/useRouter';
import NotifyMode from '../../components/NotifyMode';

const ClaimDetails: React.FC = () => {
    const { dispatch, userToken, errorResponse, activePolicy, badge, formatDate, checkArrayData, claims } = React.useContext(AppContext);
    const { polStatusDesc, receipts, claimDetails } = React.useContext(LayoutContext);
    const { params } = useRouter();

    //const [claimDetails, setClaimDetails] = React.useState<any>(null);

    if (claims && !claimDetails) {
        return (<NotifyMode
            subject='No claim found!'
            subjectSub="There is no claim for this policy."
            supportText='For questions regarding claims, please contact'
            body={<NavLink role="button" to={`/make-a-claim`}
                className="btn btn-round btn-effect-1 btn-dark my-2">Make A Claim</NavLink>}
        />)
    }

    var getFilefromURL = async function () {
        // console.log (imageuri)
        return new Promise((resolve, reject) => {
            try {
                axios.get(encodeURI(`https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf`), {
                    responseType: "arraybuffer"
                }).then((response) => {
                    resolve(response)
                })
            } catch (err) {
                reject(err)
            }
        })
    }

    const fetchFile____ = () => {
        axios({
            url: encodeURI(`https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf`),
            method: "GET",
            /* headers: {
                'Content-Type': 'application/pdf',
            }, */
            decompress: false,
            responseType: "blob" // important
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `flessss.pdf`
            );
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link?.parentNode?.removeChild(link);
        });
    }

    const fetchFile__ = () => {
        fetch('https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            });
    }

    const fetchFile_c = (event: React.MouseEvent) => {
        event.preventDefault();
        fetch(encodeURI(`https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf`))
            .then((res) => res.blob())
            .then((blob) => {
                // Read the Blob as DataURL using the FileReader API
                const reader = new FileReader();
                reader.onloadend = () => {
                    console.log(reader.result);
                    // Logs data:image/jpeg;base64,wL2dvYWwgbW9yZ...

                    // Convert to Base64 string
                    //const base64 = getBase64StringFromDataURL(reader.result);
                    //console.log(base64);
                    // Logs wL2dvYWwgbW9yZ...
                };
                reader.readAsDataURL(blob);
            });
    }

    const fetchFile = (event: React.MouseEvent) => {
        event.preventDefault();
        const pdfUrl = "https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "document.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadTxtFile = (event: React.MouseEvent) => {
        event.preventDefault();
        //const texts = encodeURI(`https://fbn-service-staging.s3.eu-west-1.amazonaws.com/nin-basic-OVIEKUGBERE-JOHN-xpressverify-1704370306-Wu9smqFQzI.pdf`)

        // text content
        const texts = ["line 1", "line 2", "line 3"]
        // file object application/pdf
        const file = new Blob(texts, { type: 'text/plain' });
        //const file = new Blob(texts, { type: 'application/pdf' });
        // anchor link
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "100ideas-" + Date.now() + ".txt";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    }

    function download_file(event: React.MouseEvent) {
        event.preventDefault();
        const element = document.createElement("a");
        element.href = "https://cdn.sstatic.net/clc/img/jobs/bg-remote-header-sm.png";
        element.download = "test.pdf";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return (<div>

        <ul className="tracking-status fade-in">
            {checkArrayData(claimDetails?.status_history) && (claimDetails?.status_history).map((claim: any) => {
                const doc = claim?.status?.has_doc && claim?.docs?.documents[0];
                return (<li
                    //onClick={download_file}
                    //onClick={(e: React.MouseEvent) => downloadTxtFile(e)}
                >
                    <div className="ts-date">{formatDate(claim?.createdAt)}</div>
                    <div className="ts-status">{claim?.status?.label}</div>
                    {doc && (<Link className="ts-link fw-underline" download to={`/dv/${claim._id}`}>
                        {claim?.status?.key === "dv-dispatched" ? 'Download & sign dispatch voucher' :
                            (claim?.status?.key === "dv-signed") ? 'Download signed dispatch voucher' : 'Download document'}
                    </Link>)}
                    <div className="ts-author">
                        {claim?.author?.user_type === "FA" ? 'Your Financial Advisor' :
                            (claim?.author && claim?.author?.user_type !== "FA") ? 'Sanlam Nigeria' : 'You'}
                    </div>

                    {claim?.comment && (<div className='font-weight-600 text-danger'>{claim?.comment}</div>)}
                </li>)
            })}
        </ul>
    </div>)

}

export default ClaimDetails;
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { Link, Outlet, useMatch } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AppContext from "../context/AppContext";
import LayoutContext from "../context/LayoutContext";
import useRouter from "../hooks/useRouter";
import Nav from "./nav";
import moment from "moment";
import Header from "./Header";
import PolicyErrorPlaceholder from "./PolicyErrorPlaceholder";


const Layout: React.FC = () => {
    const { dispatch, errorResponse, userCurrentPolicy, checkArrayData, frequency, userPolicies, userCurrentPolicyData, logoutUser, userData, checkArray, activePolicy, userDataLoading, userDataFetching, claims, badge } = React.useContext(AppContext);
    // const matchDesk = useMatch('/');
    const matchCheckout = useMatch('/checkout');
    const { location, navigate, params } = useRouter();
    //const router = useRouter();

    const [fullPR, setFullPR] = React.useState({});
    const [totalPremium, set_totalPremium] = React.useState(0);
    const [totalPremiumPaid, set_totalPremiumPaid] = React.useState(0);
    const [totalPremiumDue, set_totalPremiumDue] = React.useState(0);
    const [premium, set_premium] = React.useState(0);
    const [receipts, set_receipts] = React.useState([]);
    const [policyDurations, set_policyDurations] = React.useState('0 Year');
    const [policyFreq, set_policyFreq] = React.useState(null);

    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    useLayoutEffect(() => {
        console.log(navigator.userAgent.includes("Chrome"), 'navigator.userAgent____')
        //window.alert(navigator.userAgent)
        //if (navigator.userAgent.includes("Chrome"))
        //  window.alert('chrome')
    }, []);

    const [claimDetails, setClaimDetails] = React.useState<any>(null);

    React.useEffect(() => {
        if (checkArrayData(claims)) {

        }

        const cd = checkArrayData(claims) && claims.find((dt: any) => dt.ticket_number === params.ticket_number);
        setClaimDetails(cd);
    }, [claims, claimDetails, params.ticket_number]);

    React.useEffect(() => {
        if (userCurrentPolicyData) {
            //console.log(userPolicies, 'userCurrentPolicyData_____')
            const { payment_details, details, receipts: payments } = userCurrentPolicyData;

            //console.log('use_pol________')


            let total_premium_paid;
            const premium = payment_details?.installmentPremium;
            const total_installments = payment_details?.polTotalInstallment;

            //set premium
            set_premium(premium)

            //total premium payable
            const total_premium = total_installments * premium;
            set_totalPremium(total_premium);

            //total premium paid
            total_premium_paid = details?.totPremiumPaid;
            if (checkArrayData(payments)) {
                //total_premium_paid = payments.reduce((sum: any, b: any) => (sum + b.oprAmount), 0);
                set_receipts(payments.sort((a: any, b: any) => a.oprReceiptDate < b.oprReceiptDate ? 1 : -1));
            }/*  else {
                total_premium_paid = details?.totPremiumPaid;
            } */

            set_totalPremiumPaid(total_premium_paid)

            //total premium due //((polTotalInstallment - polPaidInstallmentNo) * installmentPremium)
            const total_premium_due = total_premium - total_premium_paid;
            set_totalPremiumDue(total_premium_due);

            //set policy duration
            const from = moment(details?.polEffectiveDate, 'DD-MM-YYYY');
            let to = moment(details?.polMaturityDate, 'DD-MM-YYYY').add(1, 'days');
            let duration = to.diff(from, `years`);
            set_policyDurations(`${duration} Year${duration > 1 ? `s` : ''}`);

            set_policyFreq(frequency(details?.endrFreqOfPayment))

        }
    }, [userCurrentPolicyData, userCurrentPolicy])

    if (!userDataLoading && !userDataFetching && !userCurrentPolicyData) {
        return (<PolicyErrorPlaceholder />);
    }

    return (
        <LayoutContext.Provider value={{
            ...userCurrentPolicyData?.details,
            beneficiaries: userCurrentPolicyData?.beneficiaries,
            dependants: userCurrentPolicyData?.dependants,
            agentDetails: userCurrentPolicyData?.agentDetails,
            totalPremium,
            totalPremiumPaid,
            totalPremiumDue,
            receipts,
            policyDurations,
            premium,
            policyFreq,
            claimDetails,
            activePolicy,
            //switchPolicy,
            //logout,
        }}>

            

            <Header />

            <div className="main-container">
                <div className="page-content container">
                    <Outlet />
                </div>
            </div>

            <Helmet>
                <script src="/assets/js/main.js"></script>
            </Helmet>
        </LayoutContext.Provider>
    );
}

export default Layout;


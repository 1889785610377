import { ErrorMessage } from '@hookform/error-message';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Currency } from '../../../components/DigitFormat';
import { Btn, Input, Select } from '../../../components/Forms';
import AppContext from '../../../context/AppContext';
import LayoutContext from '../../../context/LayoutContext';
import { MakeClaimContext } from '.';

const ClaimDone: React.FC = () => {
    const { currentIndex, ticket } = React.useContext(MakeClaimContext);

    return (<>
        {currentIndex === 0 && (<div className="notifications-container">
            <div className="nc-check-container">
                <img src="/assets/images/checked-white.svg" alt="" />
                <div className="pt-4">
                    <h2>Awesome</h2>
                    <p className="mb-0 text-white">Your claim request has been submitted successfully!</p>
                </div>
            </div>
            <div className="nc-content-container">
                <p className="mb-1">A ticket has been generated for your request. Please note the ticket number
                    below</p>
                <p className="text-dark">
                    <strong>#{ticket?.ticket_number}</strong>
                </p>
                <div>
                    <NavLink role="button" to={`/`}
                        className="btn theme-btn-3 btn-round mr-3 btn-effect-2">Continue</NavLink>

                    <NavLink role="button" to={`/claims/claim/${ticket?.ticket_number}`}
                        className="btn theme-btn-1 btn-round btn-effect-2">See Claim Details</NavLink>
                </div>
            </div>
        </div>)}
    </>)
}

export default ClaimDone;